 // http://ionicframework.com/docs/theming/
@import "node_modules/@ionic/angular/css/core.css";
@import "node_modules/@ionic/angular/css/normalize.css";
@import "node_modules/@ionic/angular/css/structure.css";
@import "node_modules/@ionic/angular/css/typography.css";

@import "node_modules/@ionic/angular/css/padding.css";
//@import "node_modules/@ionic/angular/css/float-elements.css";
@import "node_modules/@ionic/angular/css/text-alignment.css";
@import "node_modules/@ionic/angular/css/text-transformation.css";
@import "node_modules/@ionic/angular/css/flex-utils.css";
@import 'node_modules/@ionic/angular/css/display.css';
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "node_modules/bootstrap-icons/font/bootstrap-icons.scss";
//@import "./styles/de-kiosk.css";

:root {
  --primary: var(--ion-color-primary) !important;
  --primary-hover: var(--ion-color-primary-hover) !important;
  --primary-text: var(--ion-color-primary-txt) !important;
  --secondary: var(--ion-color-secondary) !important;
  --secondary-hover: var(--ion-color-secondary-hover) !important;
  --secondary-text: var(--ion-color-secondary-contrast) !important;
  --white: #ffffff !important;
  --success: #16a34a;
  --success-hover: #15803d;
  --danger: #dc2626;
  --danger-hover: #B41D1D;
  --bs-primary: var(--ion-color-primary) !important;
  --bs-primary-hover: var(--ion-color-primary-hover) !important;
  --bs-primary-text: var(--ion-color-primary-txt) !important;
  --bs-secondary: var(--ion-color-secondary) !important;
  --bs-secondary-hover: var(--ion-color-secondary-hover) !important;
  --bs-secondary-text: var(--ion-color-secondary-contrast) !important;
  --bs-white: #ffffff !important;
  --bs-success: #16a34a;
  --bs-success-hover: #15803d;
  --bs-danger: #dc2626;
  --bs-danger-hover: #B41D1D;
  --ion-color-light-hover: #f8f9fa;

  // z-index
  --zindex-dropdown: 1000;
  --zindex-absolute: 1010;
  --zindex-sticky: 1020;
  --zindex-sticky-nav: 1021;
  --zindex-fixed: 1030;
  --zindex-modal-backdrop: 1040;
  --zindex-modal: 1050;
  --zindex-popover: 1060;
  --zindex-tooltip: 1070;

  --toast-top-nav-padding: 0rem;

  --de-border-color: rgba(0, 0, 0, 0.13);
  --keyboard-offset: 0 !important;
  -webkit-overflow-scrolling: touch;

  // Kiosk
  --kiosk-base-font-size: 16px;
  --order-method-btn-height: 7rem;
  --order-method-logo-height: 5rem;
  --order-method-logo-width: 80%;
}

@media (min-width: 576px) {
  :root {
    --kiosk-base-font-size: 16px;
  }
}

@media (min-width: 768px) {
  :root {
    --kiosk-base-font-size: 17px;
  }
}

@media (min-width: 992px) {
  :root {
    --kiosk-base-font-size: 18px;
  }
}

@media (min-width: 1200px) {
  :root {
    --kiosk-base-font-size: 19px;
  }
}

@media (min-width: 1400px) {
  :root {
    --kiosk-base-font-size: 20px;
  }
}

html {
  scrollbar-width: thin;
  scrollbar-color: var(--ion-color-dark) var(--ion-color-light);
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--ion-color-light);
  border-radius: var(--cms-btn-radius);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--ion-color-dark);
  border-radius: var(--cms-btn-radius);
  cursor: grab !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--ion-color-dark-tint);
}

::selection {
  background-color: var(--primary);
  color: var(--primary-text);
}

ion-menu {
  z-index: 30000;
}

body {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  color: var(--ion-color-dark);
}

a {
  cursor: pointer;
}

p {
  margin: 0;
  font-size: 0.875rem;
}

html:not(.hydrated) body {
  display: inline-block !important;
}

button {
  cursor: pointer;
  text-transform: var(--button-text-transform) !important;
}

ion-modal {
  border-radius: var(--cms-card-radius) !important;
  --border-radius: var(--cms-card-radius) !important;
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  ion-modal {
    border-radius: 0 !important;
    --border-radius: 0 !important;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  ion-modal {
    border-radius: 0 !important;
    --border-radius: 0 !important;
  }
}

.app-loader-container {
  display: grid;
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: white;
  align-content: center;
  justify-content: center;
  z-index: 120000 !important;
}

.app-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  padding: 10px;
  border-radius: 12px;
  z-index: 500;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.progress-bar {
  transition: width 2s ease;
  background-color: var(--ion-color-primary) !important;

}

.pointer {
  cursor: pointer;
}

// DINEENGINE GLOBAL BUTTONS

.de-primary-bg {
  background: var(--ion-color-primary) !important;
}

.de-primary-bg:hover {
  background: var(--ion-color-primary-hover) !important;
}

.de-primary-bg:active {
  background: var(--ion-color-primary-active) !important;
}

.de-primary-bg:focus {
  background: var(--ion-color-primary) !important;
}

.de-ghost-bg {
  background: white !important;
}

.de-ghost-bg:hover {
  background: var(--ion-color-primary-hover) !important;
  border-color: var(--ion-color-primary-hover) !important;
  color: var(--ion-color-primary-hover-txt) !important;
}

.de-ghost-bg:active {
  background: var(--ion-color-primary-active) !important;
  border-color: var(--ion-color-primary-active) !important;
  color: var(--ion-color-primary-hover-txt) !important;
}

.de-ghost-bg:focus {
  background: var(--ion-color-primary) !important;
  border-color: var(--ion-color-primary) !important;
  color: var(--ion-color-primary-hover-txt) !important;
}

.de-primary-border {
  border-color: var(--ion-color-primary) !important;
}

.de-primary-text {
  color: var(--ion-color-primary) !important;
}

.de-primary-mobile-btn {
  --background: var(--ion-color-primary);
  color: var(--ion-color-primary-txt);
}

.de-secondary-mobile-btn {
  --background: var(--ion-color-secondary);
  color: var(--ion-color-secondary-txt);
}

.de-danger-btn {
  height: 3rem;
  --background: var(--ion-color-danger);
  background: var(--ion-color-danger);
  color: #ffffff;
  font-size: 1rem;
  border: none;
  --box-shadow: none !important;
  text-transform: var(--button-text-transform);
  font-weight: bold;
  transition: 0.3s;
  --transition: 0.3s;
  --border-radius: var(--cms-btn-radius) !important;
  border-radius: var(--cms-btn-radius) !important;
  padding: 0.5rem 0.75rem;

  &-small {
    @extend .de-danger-btn;
    height: 38px;
    padding: 0.5rem 1rem;
    min-width: 38px;
  }

  &-sm {
    @extend .de-danger-btn;
    height: 38px;
    padding: 0.375rem 0.5rem;
    min-width: 38px;
  }
}


.de-danger-btn:disabled {
  background-color: var(--ion-color-danger) !important;
  opacity: 0.5;

  &:hover {
    background-color: var(--ion-color-danger) !important;
  }
}

.de-danger-btn:active {
  --background: var(--ion-color-danger-shade) !important;
  background: var(--ion-color-danger-shade) !important;
  color: #ffffff !important;
}

@media (min-width: 992px) {
  .de-danger-btn:hover {
    --background: var(--ion-color-danger-shade) !important;
    background: var(--ion-color-danger-shade) !important;
    color: #ffffff !important;
    cursor: pointer;
  }
}

.de-ionic-success-toast {
  --background: #28a745;
  --button-color: white;
  --color: white;
  --border-radius: var(--cms-btn-radius);
}

.de-ionic-warning-toast {
  --background: #ffc107;
  --button-color: black;
  --color: black;
  --border-radius: var(--cms-btn-radius);
}

.de-ionic-error-toast {
  --background: #dc3545;
  --button-color: white;
  --color: white;
  --border-radius: var(--cms-btn-radius);
}

@media(max-width: 991px) {
  .de-ionic-success-toast {
    transform: translateY(64px) !important;
  }

  .de-ionic-warning-toast {
    transform: translateY(64px) !important;
  }

  .de-ionic-error-toast {
    transform: translateY(64px) !important;
  }
}

@media(min-width: 992px) {
  .de-ionic-success-toast {
    transform: translateY(-2rem) !important;
  }

  .de-ionic-warning-toast {
    transform: translateY(-2rem) !important;
  }

  .de-ionic-error-toast {
    transform: translateY(-2rem) !important;
  }
}

.dark-link {
  color: var(--dark);
  font-weight: bold;
  text-decoration: underline dotted;
  text-decoration-thickness: 2px;
  text-underline-offset: 3px;
  cursor: pointer;
  text-transform: capitalize !important;

  &:hover {
    color: var(--dark);
    text-decoration: underline;
    text-decoration-thickness: 2px;
  }

}

.primary-link {
  color: var(--ion-color-primary);
  font-weight: bold;
  text-decoration: underline dotted;
  text-decoration-thickness: 2px;
  text-underline-offset: 3px;
  text-transform: capitalize !important;

  &:hover {
    color: var(--ion-color-primary);
    text-decoration: underline;
    text-decoration-thickness: 2px;
  }

}

.de-primary-link {
  color: var(--ion-color-primary);
  background-color: transparent;
  border: none;
  text-decoration: underline dotted !important;
  text-underline-offset: 0.25rem;
  font-size: 1rem;
  text-transform: capitalize !important;
  font-weight: bold;
  transition: 0.3s;
  --transition: 0.3s;
}

.de-primary-link:disabled {
  color: var(--ion-color-primary) !important;
  opacity: 0.5;

  &:hover {
    color: var(--ion-color-primary) !important;
    cursor: pointer;
  }
}

.de-primary-link:active {
  --color: var(--ion-color-primary-hover) !important;
  color: var(--ion-color-primary-hover) !important;
}

@media (min-width: 992px) {
  .de-primary-link:hover {
    --color: var(--ion-color-primary-hover) !important;
    color: var(--ion-color-primary-hover) !important;
    cursor: pointer;
  }
}

.de-primary-link:focus {
  color: var(--ion-color-primary);
}

 .de-dark-link {
   color: var(--ion-color-dark);
   background-color: transparent;
   border: none;
   text-decoration: underline dotted !important;
   text-underline-offset: 0.25rem;
   font-size: 1rem;
   text-transform: capitalize !important;
   font-weight: bold;
   transition: 0.3s;
   --transition: 0.3s;
 }

 .de-dark-link:disabled {
   color: var(--ion-color-dark) !important;
   opacity: 0.5;

   &:hover {
     color: var(--ion-color-dark) !important;
   }
 }

 .de-dark-link:active {
   --color: var(--ion-color-dark-hover) !important;
   color: var(--ion-color-dark-hover) !important;
 }

 @media (min-width: 992px) {
   .de-dark-link:hover {
     --color: var(--ion-color-dark-hover) !important;
     color: var(--ion-color-dark-hover) !important;
     cursor: pointer;
   }
 }

 .de-dark-link:focus {
   color: var(--ion-color-dark);
 }

.de-danger-link {
  color: var(--ion-color-danger);
  background-color: transparent;
  border: none;
  text-decoration: underline dotted !important;
  text-underline-offset: 0.25rem;
  font-size: 1rem;
  text-transform: capitalize !important;
  font-weight: bold;
  transition: 0.3s;
  --transition: 0.3s;
}

.de-danger-link:disabled {
  color: var(--ion-color-danger) !important;
  opacity: 0.5;

  &:hover {
    color: var(--ion-color-danger) !important;
  }
}

.de-danger-link:active {
  --color: var(--ion-color-danger-hover) !important;
  color: var(--ion-color-danger-hover) !important;
}

@media (min-width: 992px) {
  .de-danger-link:hover {
    --color: var(--ion-color-danger-hover) !important;
    color: var(--ion-color-danger-hover) !important;
    cursor: pointer;
  }
}

.de-danger-link:focus {
  color: var(--ion-color-primary);
}

.de-info-link {
  color: var(--ion-color-info);
  background-color: transparent;
  border: none;
  text-decoration: underline dotted !important;
  text-underline-offset: 0.25rem;
  font-size: 1rem;
  text-transform: capitalize !important;
  font-weight: bold;
  transition: 0.3s;
  --transition: 0.3s;
}

.de-info-link:disabled {
  color: var(--ion-color-info) !important;
  opacity: 0.5;

  &:hover {
    color: var(--ion-color-info) !important;
  }
}

.de-info-link:active {
  --color: var(--ion-color-info-hover) !important;
  color: var(--ion-color-info-hover) !important;
}

@media (min-width: 992px) {
  .de-info-link:hover {
    --color: var(--ion-color-info-hover) !important;
    color: var(--ion-color-info-hover) !important;
    cursor: pointer;
  }
}

.de-info-link:focus {
  color: var(--ion-color-primary);
}

.de-btn-loader {
  border: 3px solid transparent;
  border-radius: 50%;
  border-top: 3px solid var(--ion-color-primary-txt);
  width: 30px;
  height: 35px;
  -webkit-animation: spin 1s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

.de-sm-loader {
  border: 1px solid transparent;
  border-radius: 50%;
  //border-top: 1px solid var(--ion-color-dark);
  width: 12px;
  height: 15px;
  -webkit-animation: spin 1s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite;
}

.de-md-loader {
  border: 2px solid transparent;
  border-radius: 50%;
  border-top: 2px solid var(--ion-color-primary-txt);
  width: 18px;
  height: 22px;
  -webkit-animation: spin 1s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite;
}

.de-btn-loader-primary {
  border-top: 3px solid var(--ion-color-primary);
  margin: 0;
  float: right;
  margin-right: 5px;
}

.de-btn-loader-secondary {
  border: 3px solid transparent;
  border-radius: 50%;
  border-top: 3px solid var(--ion-color-secondary);
  width: 30px;
  height: 35px;
  -webkit-animation: spin 1s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

.de-btn-mini-loader {
  border: 2px solid transparent;
  border-radius: 50%;
  border-top: 2px solid #333333;
  border-right: 2px solid #333333;
  width: 14px;
  height: 14px;
  -webkit-animation: spin 1s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite;
  margin: 0 auto;
  margin-top: 4px;
}

.de-facebook-btn {
  height: 50px;
  --background: #3B5998;
  background: #3B5998;
  color: white;
  font-size: 1rem;
  border: none;
  --box-shadow: none !important;
  text-transform: uppercase;
  border-radius: var(--cms-btn-radius);
}

.de-facebook-btn:hover {
  color: #fff;
  opacity: 0.8;
}

.de-apple-btn {
  display: grid;
  place-items: center;
  height: 50px;
  --background: #000000;
  background: #000000;
  color: white;
  font-size: 22px;
  line-height: 23px;
  text-transform: none !important;
  border-radius: var(--cms-btn-radius);
  border: none;
  --box-shadow: none !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;

  div {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;

    div {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    }
  }
}

.de-apple-btn:hover {
  cursor: pointer;
  height: 50px;
  --background: #000000;
  background: #000000;
  color: white;
  font-size: 22px;
  border-radius: var(--cms-btn-radius);
  border: none;
  --box-shadow: none !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.de-secondary-fill-btn {
  height: 50px;
  --background: var(--ion-color-secondary);
  color: var(--ion-color-secondary-txt);
  font-size: 1rem;
  border: none;
  --box-shadow: none !important;
  text-transform: var(--button-text-transform);
}

.de-secondary-fill-btn:hover {
  --background: var(--ion-color-secondary-hover) !important;
  color: var(--ion-color-secondary-hover-txt) !important;
}

.de-btn {
  height: 3rem;
  font-size: 1rem;
  border: none;
  text-transform: var(--button-text-transform);
  font-weight: bold;
  transition: 0.3s;
  --border-radius: var(--cms-btn-radius);
  border-radius: var(--cms-btn-radius);
  padding: 0.5rem 0.75rem;
  cursor: pointer;

  &-sm {
    @extend .de-btn;
    height: 38px;
    padding: 0.375rem 0.5rem;
  }

  &:disabled {
    @extend .de-btn;
    background-color: var(--ion-color-primary) !important;
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      background-color: var(--ion-color-primary) !important;
    }
  }

  &:active {
    --background: var(--ion-color-primary-hover) !important;
    background: var(--ion-color-primary-hover) !important;
    color: var(--ion-color-primary-hover-txt) !important;
  }
}

.de-primary-btn {
  height: 3rem;
  --background: var(--ion-color-primary);
  background: var(--ion-color-primary);
  color: var(--ion-color-primary-txt);
  border: 1px solid var(--ion-color-primary) !important;
  font-size: 1rem;
  border: none;
  text-transform: var(--button-text-transform);
  font-weight: bold;
  transition: 0.3s;
  --border-radius: var(--cms-btn-radius);
  border-radius: var(--cms-btn-radius);
  padding: 0.5rem 0.75rem;

  &-sm {
    @extend .de-primary-btn;
    height: 38px;
    padding: 0.375rem 0.5rem;
    min-width: 38px;

    &:hover {
      border: 1px solid var(--ion-color-primary-hover) !important;
    }
  }

  &:hover {
    border: 1px solid var(--ion-color-primary-hover) !important;
  }
}

.de-primary-btn:disabled {
  background-color: var(--ion-color-primary) !important;
  opacity: 0.5;

  &:hover {
    background-color: var(--ion-color-primary) !important;
  }

}

.de-primary-btn:active {
  --background: var(--ion-color-primary-hover) !important;
  background: var(--ion-color-primary-hover) !important;
  color: var(--ion-color-primary-hover-txt) !important;
}

@media (min-width: 814px) {
  .de-primary-btn:hover {
    --background: var(--ion-color-primary-hover) !important;
    background: var(--ion-color-primary-hover) !important;
    color: var(--ion-color-primary-hover-txt) !important;
    cursor: pointer;
  }

  .de-btn:hover {
    cursor: pointer;
  }
}

.de-primary-btn:focus {
  background: var(--ion-color-primary);
  color: var(--ion-color-primary-txt);
  box-shadow: 0 0 0 0.25rem var(--ion-color-primary-rgba);
  --border-radius: var(--cms-btn-radius);
  border-radius: var(--cms-btn-radius);
}

.de-primary-btn-outline {
  height: 3rem;
  color: var(--ion-color-primary);
  border: 1px solid var(--ion-color-primary) !important;
  background: transparent;
  transition: all 0.3s ease-in-out;
  border-radius: var(--cms-btn-radius);
  font-size: 1rem;
  text-transform: var(--button-text-transform);
  font-weight: bold;
  padding: 0.5rem 0.75rem;

  &-sm {
    @extend .de-primary-btn-outline;
    height: 38px !important;
    padding: 0.375rem 0.5rem;
  }

}

.de-primary-btn-outline:active {
  --background: var(--ion-color-primary) !important;
  background: var(--ion-color-primary) !important;
  color: var(--ion-color-primary-hover-txt) !important;
  border-color: var(--ion-color-primary);
}

@media (min-width: 814px) {
  .de-primary-btn-outline:hover {
    --background: var(--ion-color-primary) !important;
    background: var(--ion-color-primary) !important;
    color: var(--ion-color-primary-hover-txt) !important;
    border-color: var(--ion-color-primary);
    cursor: pointer;
  }
}

.de-primary-btn-outline:focus {
  box-shadow: 0 0 0 0.25rem var(--ion-color-primary-rgba);
}

.de-secondary-btn {
  height: 3rem;
  --background: var(--ion-color-secondary);
  background: var(--ion-color-secondary);
  color: var(--ion-color-secondary-txt);
  font-size: 1rem;
  border: none;
  text-transform: var(--button-text-transform);
  font-weight: bold;
  transition: 0.3s;
  border-radius: var(--cms-btn-radius);
  padding: 0.5rem 0.75rem;

  &-sm {
    @extend .de-secondary-btn;
    height: 38px;
    padding: 0.375rem 0.5rem;

    &:hover {
      border: 1px solid var(--ion-color-secondary-hover) !important;
    }
  }

  &:hover {
    border: 1px solid var(--ion-color-secondary-hover) !important;
  }
}

.de-secondary-btn:disabled {
  background-color: var(--ion-color-secondary) !important;
  opacity: 0.5;

  &:hover {
    background-color: var(--ion-color-secondary) !important;
  }
}

.de-secondary-btn:active {
  --background: var(--ion-color-secondary-hover) !important;
  background: var(--ion-color-secondary-hover) !important;
  color: var(--ion-color-secondary-hover-txt) !important;
}

@media (min-width: 814px) {
  .de-secondary-btn:hover {
    --background: var(--ion-color-secondary-hover) !important;
    background: var(--ion-color-secondary-hover) !important;
    color: var(--ion-color-secondary-hover-txt) !important;
    cursor: pointer;
  }
}

.de-secondary-btn:focus {
  background: var(--ion-color-secondary);
  color: var(--ion-color-secondary-txt);
  box-shadow: 0 0 0 0.25rem var(--ion-color-secondary-rgba);
}

.de-secondary-btn-outline {
  height: 3rem;
  color: var(--ion-color-secondary);
  border: 1px solid var(--ion-color-secondary);
  background: transparent;
  transition: all 0.3s ease-in-out;
  border-radius: var(--cms-btn-radius);
  font-size: 1rem;
  text-transform: var(--button-text-transform);
  font-weight: bold;
  padding: 0.5rem 0.75rem;
  cursor: pointer;

  &-sm {
    @extend .de-secondary-btn-outline;
    height: 38px;
    padding: 0.375rem 0.5rem;
  }

}

.de-secondary-btn-outline:active {
  --background: var(--ion-color-secondary) !important;
  background: var(--ion-color-secondary) !important;
  color: var(--ion-color-secondary-hover-txt) !important;
  border-color: var(--ion-color-secondary);
}

.de-secondary-btn-outline:hover {
  --background: var(--ion-color-secondary) !important;
  background: var(--ion-color-secondary) !important;
  color: var(--ion-color-secondary-hover-txt) !important;
  border-color: var(--ion-color-secondary);
}

.de-secondary-btn-outline:focus {
  background: var(--ion-color-secondary);
  color: var(--ion-color-secondary-txt);
  border-color: var(--ion-color-secondary);
  box-shadow: 0 0 0 0.25rem var(--ion-color-secondary-rgba);
}

.de-dark-btn {
  height: 3rem;
  --background: var(--ion-color-dark);
  background: var(--ion-color-dark);
  color: var(--ion-color-light);
  font-size: 1rem;
  border: none;
  text-transform: var(--button-text-transform);
  font-weight: bold;
  transition: 0.3s;
  border-radius: var(--cms-btn-radius);
  padding: 0.5rem 0.75rem;

  &-sm {
    @extend .de-dark-btn;
    height: 38px;
    padding: 0.375rem 0.5rem;
  }
}

.de-dark-btn:disabled {
  background-color: var(--ion-color-dark-dark) !important;
  opacity: 0.5;

  &:hover {
    background-color: var(--ion-color-dark) !important;
  }

}

.de-dark-btn:active {
  --background: var(--ion-color-dark-tint) !important;
  background: var(--ion-color-dark-tint) !important;
  color: var(--ion-color-light) !important;
}

@media (min-width: 814px) {
  .de-dark-btn:hover {
    --background: var(--ion-color-dark-tint) !important;
    background: var(--ion-color-dark-tint) !important;
    color: var(--ion-color-light) !important;
    cursor: pointer;
  }
}

.de-dark-btn:focus {
  background: var(--ion-color-dark);
  color: var(--ion-color-light);
  box-shadow: 0 0 0 0.25rem var(--ion-color-dark-rgba);
}

.de-dark-btn-outline {
  height: 3rem;
  color: var(--ion-color-dark);
  border: 1px solid var(--ion-color-dark);
  background: transparent;
  transition: all 0.3s ease-in-out;
  border-radius: var(--cms-btn-radius);
  font-size: 1rem;
  text-transform: var(--button-text-transform);
  font-weight: bold;
  padding: 0.5rem 0.75rem;

  &-sm {
    @extend .de-dark-btn-outline;
    height: 38px;
    padding: 0.375rem 0.5rem;
  }

}

.de-dark-btn-outline:active {
  --background: var(--ion-color-dark) !important;
  background: var(--ion-color-dark) !important;
  color: var(--ion-color-light) !important;
  border-color: var(--ion-color-dark);
}

.de-dark-btn-outline:hover {
  --background: var(--ion-color-dark) !important;
  background: var(--ion-color-dark) !important;
  color: var(--ion-color-light) !important;
  border-color: var(--ion-color-dark);
}

.de-dark-btn-outline:focus {
  background: var(--ion-color-dark);
  color: var(--ion-color-light);
  border-color: var(--ion-color-dark);
  box-shadow: 0 0 0 0.25rem var(--ion-color-dark-rgba);
}

.de-light-btn {
  height: 3rem;
  --background: var(--ion-color-light);
  background: var(--ion-color-light);
  color: var(--ion-color-dark);
  font-size: 1rem;
  border: none;
  text-transform: var(--button-text-transform);
  font-weight: bold;
  transition: 0.3s;
  border-radius: var(--cms-btn-radius);
  padding: 0.5rem 0.75rem;

  &-sm {
    @extend .de-light-btn;
    height: 38px;
    padding: 0.375rem 0.5rem;
  }
}

.de-light-btn:disabled {
  background-color: var(--ion-color-light-light) !important;
  opacity: 0.5;

  &:hover {
    background-color: var(--ion-color-light) !important;
  }

}

.de-light-btn:active {
  --background: var(--ion-color-light-tint) !important;
  background: var(--ion-color-light-tint) !important;
  color: var(--ion-color-dark) !important;
}

@media (min-width: 814px) {
  .de-light-btn:hover {
    --background: var(--ion-color-light-tint) !important;
    background: var(--ion-color-light-tint) !important;
    color: var(--ion-color-dark) !important;
    cursor: pointer;
  }
}

.de-light-btn:focus {
  background: var(--ion-color-light);
  color: var(--ion-color-dark);
  box-shadow: 0 0 0 0.25rem var(--ion-color-light-rgba);
}

.de-light-btn-outline {
  height: 3rem;
  color: var(--ion-color-light);
  border: 1px solid var(--ion-color-light);
  background: transparent;
  transition: all 0.3s ease-in-out;
  border-radius: var(--cms-btn-radius);
  font-size: 1rem;
  text-transform: var(--button-text-transform);
  font-weight: bold;
  padding: 0.5rem 0.75rem;

  &-sm {
    @extend .de-light-btn-outline;
    height: 38px;
    padding: 0.375rem 0.5rem;
  }

}

.de-light-btn-outline:active {
  --background: var(--ion-color-light) !important;
  background: var(--ion-color-light) !important;
  color: var(--ion-color-dark) !important;
  border-color: var(--ion-color-light);
}

.de-light-btn-outline:focus {
  background: var(--ion-color-light);
  color: var(--ion-color-light);
  border-color: var(--ion-color-light);
  box-shadow: 0 0 0 0.25rem var(--ion-color-light-rgba);
}


.de-secondary-link {
  color: var(--ion-color-secondary);
  background-color: transparent;
  border: none;
  text-decoration: underline dotted !important;
  text-underline-offset: 0.25rem;
  font-size: 1rem;
  text-transform: capitalize !important;
  font-weight: bold;
  transition: 0.3s;
  --transition: 0.3s;
}

.de-secondary-link:disabled {
  color: var(--ion-color-secondary) !important;
  opacity: 0.5;

  &:hover {
    color: var(--ion-color-seecondary) !important;
  }
}

.de-secondary-link:active {
  --color: var(--ion-color-secondary-hover) !important;
  color: var(--ion-color-secondary-hover) !important;
}

@media (min-width: 992px) {
  .de-primary-secondary:hover {
    --color: var(--ion-color-secondary-hover) !important;
    color: var(--ion-color-secondary-hover) !important;
    cursor: pointer;
  }
}

.de-secondary-link:focus {
  color: var(--ion-color-secondary);
}

.de-tertiary-btn {
  font-weight: bold;
  height: 3rem;
  --background: var(--ion-color-tertiary);
  color: var(--ion-color-light);
  font-size: 1rem;
  -moz-border: none;
  --box-shadow: none !important;
  text-transform: var(--button-text-transform);
  // transition: 0.3s;
  --transition: 0.3s;
}

.de-success-btn {
  height: 3rem;
  --background: var(--ion-color-success);
  background: var(--ion-color-success);
  color: var(--ion-color-success-txt);
  border: 1px solid var(--ion-color-success) !important;
  font-size: 1rem;
  border: none;
  text-transform: var(--button-text-transform);
  font-weight: bold;
  transition: 0.3s;
  --border-radius: var(--cms-btn-radius);
  border-radius: var(--cms-btn-radius);
  padding: 0.5rem 0.75rem;

  &-sm {
    @extend .de-success-btn;
    height: 38px;
    padding: 0.375rem 0.5rem;

    &:hover {
      border: 1px solid var(--ion-color-success-shade) !important;
      color: var(--ion-color-success-txt);
    }
  }

  &:hover {
    border: 1px solid var(--ion-color-success-shade) !important;
    color: var(--ion-color-success-txt);
  }
}

.de-success-btn:disabled {
  background-color: var(--ion-color-success) !important;
  color: var(--ion-color-success-txt);
  opacity: 0.5;

  &:hover {
    background-color: var(--ion-color-success) !important;
    color: var(--ion-color-success-txt);
  }

}

.de-success-btn:active {
  --background: var(--ion-color-success-shade) !important;
  background: var(--ion-color-success-shade) !important;
  color: var(--ion-color-success-txt);
}

@media (min-width: 814px) {
  .de-success-btn:hover {
    --background: var(--ion-color-success-shade) !important;
    background: var(--ion-color-success-shade) !important;
    color: var(--ion-color-success-txt);
    cursor: pointer;
  }
}

.pill-btn {
  width: fit-content;
  padding: 0.5rem 0.75rem;
  border-radius: 1000px;
  transition: 0.3s;
  text-decoration: none;

  &-default {
    @extend .pill-btn;
    color: var(--ion-color-dark);
    background: var(--ion-color-medium);
    border: 1px solid var(--de-border-color);

    &-active {
      color: var(--ion-color-primary-txt);
      background: var(--ion-color-primary-shade);
      border: 1px solid var(--ion-color-primary);
    }

    &:hover {
      color: var(--ion-color-primary-txt);
      background: var(--ion-color-primary);
      border: 1px solid var(--ion-color-primary);
    }

    &-outline {
      @extend .pill-btn-default;
      font-size: 0.875rem;
      padding: 0.375rem 0.5rem;
      background: transparent;
      border: 1px solid var(--ion-color-medium);
      color: var(--ion-color-medium);
    }
  }

  &-primary {
    @extend .pill-btn;
    background: var(--ion-color-primary);
    border: 1px solid var(--de-border-color);

    &-outline {
      @extend .pill-btn-primary;
      font-size: 0.875rem;
      padding: 0.375rem 0.5rem;
      background: rgba(var(--ion-color-primary-rgb), 0.3);
      border: 1px solid var(--ion-color-primary);
      color: var(--ion-color-primary-txt);

      &:hover {
        color: var(--ion-color-primary-txt);
        background: var(--ion-color-primary);
      }

    }
  }

  &-secondary {
    @extend .pill-btn;
    background: var(--ion-color-secondary);
    border: 1px solid var(--de-border-color);

    &-outline {
      @extend .pill-btn-secondary;
      font-size: 0.875rem;
      padding: 0.375rem 0.5rem;
      background: transparent;
      border: 1px solid var(--ion-color-secondary);
      color: var(--ion-color-secondary);
    }
  }

  &-success {
    @extend .pill-btn-success;
    background: var(--ion-color-success);
    border: 1px solid var(--de-border-color);

    &-outline {
      @extend .pill-btn-success;
      font-size: 0.875rem;
      padding: 0.375rem 0.5rem;
      background: transparent;
      border: 1px solid var(--ion-color-success);
      color: var(--ion-color-success);
    }
  }

  &-warning {
    @extend .pill-btn;
    background: var(--ion-color-warning);
    border: 1px solid var(--de-border-color);

    &-outline {
      @extend .pill-btn-warning;
      font-size: 0.875rem;
      padding: 0.375rem 0.5rem;
      background: transparent;
      border: 1px solid var(--ion-color-warning);
      color: var(--ion-color-warning);
    }
  }

  &-danger {
    @extend .pill-btn;
    background: var(--ion-color-danger);
    border: 1px solid var(--de-border-color);
    color: var(--ion-color-light);

    &-outline {
      @extend .pill-btn-danger;
      font-size: 0.875rem;
      padding: 0.375rem 0.5rem;
      background: transparent;
      border: 1px solid var(--ion-color-danger);
      color: var(--ion-color-danger);

      &:hover {
        background: var(--ion-color-danger);
        color: var(--ion-color-light);
      }
    }
  }

  &-dark {
    @extend .pill-btn;
    background: var(--ion-color-dark);
    color: var(--ion-color-light);
    border: 1px solid var(--ion-color-dark);

    &-outline {
      @extend .pill-btn-dark;
      font-size: 0.875rem;
      padding: 0.375rem 0.5rem;
      background: transparent;
      border: 1px solid var(--ion-color-dark);
      color: var(--ion-color-dark);

      &:hover {
        background: var(--ion-color-dark-tint);
        color: var(--ion-color-light);
        border: 1px solid var(--ion-color-dark-tint);
      }
    }

    &:hover {
      background: var(--ion-color-dark-tint);
      border: 1px solid var(--ion-color-dark-tint);
    }
  }

  &-light {
    @extend .pill-btn;
    background: var(--ion-color-light);
    color: var(--ion-color-dark);
    border: 1px solid var(--de-border-color);

    &-outline {
      @extend .pill-btn-light;
      font-size: 0.875rem;
      padding: 0.375rem 0.5rem;
      background: transparent;
      border: 1px solid var(--ion-color-light);
      color: var(--ion-color-light);
    }
  }

  &-navbar {
    @extend .pill-btn;
    background: var(--nav-text-color);
    color: var(--nav-background-color);
    border: 1px solid var(--nav-text-color);

    &-inactive {
      @extend .pill-btn-navbar;
      font-size: 0.875rem;
      padding: 0.375rem 0.5rem;
      background: var(--nav-background-color);
      border-style: solid;
      border-width: 1px;
      border-color: transparent;
      color: var(--nav-text-color);

      &:hover {
        background: var(--nav-background-color) !important;
        color: var(--nav-text-color) !important;
        border: 1px solid var(--nav-text-color) !important;
      }
    }

    &:hover {
      background: var(--nav-text-color);
      border: 1px solid var(--nav-text-color);
    }
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
    text-decoration: none;
  }

  &-sm {
    @extend .pill-btn;
    font-size: 0.875rem;
    padding: 0.375rem 0.5rem;
  }
}


.de-transition {
  transition: 0.3s;
}

.navbar-li-button {
  --border-radius: 0;
  height: 50px;
  --background: var(--ion-color-primary);
  color: var(--ion-color-primary-txt);
  font-size: 1em;
  border: none;
  --box-shadow: none !important;
}

.navbar-li-button:hover {
  --background: var(--ion-color-primary-hover);
  color: var(--ion-color-primary-hover-txt);
}

.de-shadow {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07),
  0 2px 4px rgba(0, 0, 0, 0.07),
  0 4px 8px rgba(0, 0, 0, 0.07),
  0 8px 16px rgba(0, 0, 0, 0.07),
  0 16px 32px rgba(0, 0, 0, 0.07),
  0 32px 64px rgba(0, 0, 0, 0.07);
}

.de-nav-shadow {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07),
  0 2px 4px rgba(0, 0, 0, 0.07),
  0 4px 8px rgba(0, 0, 0, 0.07),
  0 8px 16px rgba(0, 0, 0, 0.07);
}

.de-unselectable-item {
  opacity: 0.3;
  pointer-events: none;
}

// MENU CARDS DEFAULT

.no-image {
  display: block;
  max-height: 1000px;
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: relative;
  border-bottom: 1px solid var(--de-border-color);
  background-color: var(--ion-color-card-background-color);
}

.no-image-text {
  margin: 0;
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: bold;
  font-size: 2vw;
  text-transform: uppercase;
  color: var(--ion-color-card-text-color);

  @media (min-width: 1200px) {
    font-size: 1.25vw;
  }
}

//DINEENGINE FORMS

//.custom-switch {
//  padding-left: $custom-switch-width + $custom-control-gutter;
//
//  .custom-control-label {
//    padding-left: 1.5rem;
//
//    &::before {
//      left: -($custom-switch-width + $custom-control-gutter);
//      width: 3rem;
//      height: 2rem;
//      pointer-events: all;
//      // stylelint-disable-next-line property-disallowed-list
//      border-radius: 1000rem;
//    }
//
//    &::after {
//      top: 0.25rem;
//      left: -1.875rem;
//      width: 1.5rem;
//      height: 1.5rem;
//      background-color: $custom-control-indicator-border-color;
//      // stylelint-disable-next-line property-disallowed-list
//      border-radius: 1000rem;
//      @include transition(transform .15s ease-in-out, $custom-forms-transition);
//    }
//  }
//
//  .custom-control-input:checked ~ .custom-control-label {
//    &::after {
//      background-color: $custom-control-indicator-bg;
//      transform: translateX($custom-switch-width - $custom-control-indicator-size);
//    }
//  }
//
//  .custom-control-input:disabled {
//    &:checked ~ .custom-control-label::before {
//      @include gradient-bg($custom-control-indicator-checked-disabled-bg);
//    }
//  }
//}

ion-label {
  font-size: 16px;
  padding: 1vh 0;
}

ion-input {
  margin: 1vh 0;
  --padding-start: 10px !important;
}

input.form-control, select.form-select {
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(var(--ion-color-primary-rgb), 0.25);
    border-color: var(--ion-color-primary-tint);
  }
}

.de-input {
  background-color: var(--ion-color-light);
}

.tableside-input-field {
  height: 44px;
  font-size: 1rem !important;
}

ion-textarea {
  margin: 1vh 0;
  height: 15vh;
  --padding-start: 10px !important;
}

#submit {
  font-weight: bolder;
  text-transform: var(--button-text-transform);
}


// DINEENGINE FONTS

.f-10 {
  font-size: 10px;
}

.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
}

.header-font {
  // Header font is set in theme.service.ts
}

.paragraph-font {
  // Paragraph font is set in theme.service.ts
}

.toolbar-container {
  contain: none !important;
  overflow: visible !important;
}

@media(max-width: 991px) {
  .dineengine-button {
    --border-radius: 8px;
  }

  .dineengine-secondary-button {
    --border-radius: 8px;
  }
}

.searchbar-input {
  box-shadow: none !important;
  border: 1px solid #707070 !important;
}

.de-form-error {
  color: var(--ion-color-danger);

  div {
    font-size: .75em
  }
}

.form-error {
  color: var(--ion-color-danger);
  margin-top: 2px;
  height: 15px;
  margin-bottom: 8px;
  font-size: 0.75rem;
}

.form-floating {
  label {
    z-index: 6;
  }

  input {
    max-height: 58px;
  }
}

.de-form-success {
  color: var(--ion-color-success);

  div {
    font-size: .75em
  }
}

.de-mobile-safe-inset {
  height: calc(64px + env(safe-area-inset-top));
}

.realItem {
  // color: red;
  opacity: 1;
  animation: fadeIn 0.5s ease;
  // box-shadow: 1px 1px 3px 0px #888888;
  box-shadow: 0 4px 16px #0000001f;
  // border-radius: 8px;
  // animation-fill-mode: forwards;
}

.fake-card {
  border-radius: 8px;
  --border-radius: 8px;
}

@keyframes fadeIn {
  0% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

@keyframes ripple {
  0% {
    background-color: rgba(0, 0, 0, 0.05);
    transform: scale(1);
  }

  30% {
    transform: scale(0.95);
  }

  40% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(1.1);
  }

  60% {
    background-color: rgba(0, 0, 0, 0.125);
  }

  100% {
    background-color: rgba(0, 0, 0, 0);
    transform: scale(1);
  }
}

@keyframes rippleDesktop {
  0% {
    background-color: rgba(0, 0, 0, 0.05);
    transform: scale(1);
  }

  30% {
    transform: scale(0.95);
  }

  40% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(1.1);
  }

  60% {
    background-color: rgba(0, 0, 0, 0.125);
  }

  100% {
    background-color: rgba(0, 0, 0, 0.05);
    transform: scale(1);
  }
}

.ripple {
  border-radius: 50%;
}

.ripple {
  // background-color: rgba(0, 0, 0, 0.1) !important;
  // opacity: 1;
  // transition: 0.5s;

  //transform: translate3d(0);
  //animation: ripple 1s ease;
}

// ion-icon {
//   border-radius: 50%;
//   transition: 0.1s;
//   padding: 6px;
// }
@media (pointer: fine) {
  //ion-icon:hover {
  //  background-color: rgba(0, 0, 0, 0.05) !important;
  //}

  .ripple {
    animation: rippleDesktop 1s ease;
  }
}

.ham-logo {
  margin: calc(20px + env(safe-area-inset-top, 0)) auto 20px;
  height: 50px;
  max-width: 250px;
  place-content: center;
}

.navbar-text-item {
  cursor: pointer;
  font-size: 1rem;
  max-width: none;
  text-align: center;
  white-space: nowrap;
  margin: auto 0;
}

.navbar-text-item:hover {
  text-decoration: underline;
}

.navbar-button-text {
  text-align: center;
  white-space: nowrap;
  text-emphasis: none;
  font-weight: bold;
}

.select-picker {
  width: 100%;
  height: 40px;
  -webkit-border-radius: 0;
}

.navbar-ul {
  margin: 0;
  padding: auto;
}

.navbar-list {
  //display: inline-block;
  //position: absolute;
  //top: 50%;
  //right: 16px;
  //transform: translate(0%, -50%);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.navbar-li {
  display: inline;
  margin: 0 6px;
}

.navbar-order-details {
  display: inline-block;
  //margin-left: .5rem;
  //position: absolute;
  //top: 50%;
  //transform: translate(0%, -50%);
}

.method-text {
  text-transform: uppercase;
  font-size: .75em;
}

.location-text {
  margin-top: 4px;
  font-size: .875em;
  color: var(--ion-color-dark);
}

.order-details-col {
  cursor: pointer;
  padding-left: 0;
  padding-right: 10px;
}

.divide {
  border-left: .5px dashed var(--ion-color-dark);
  margin: 0 10px;
  padding: 0 10px;
}

.disable-ripple {
  --ripple-color: transparent;
}

// .cart-button {
//   background-color: var(--ion-color-tertiary);
//   white-space: nowrap !important;
//   width: 100px;
//   height: 50px;
//   margin: 0px 0;
//   border-radius: 10px;
//   padding-right: 40px;
//   color: white;
//   margin-right: 20px;
//   margin-left: 10px;
// }

.cart-button[tab="cart"] {
  border-radius: unset;
}

ion-item {
  cursor: pointer;
}

ion-header,
ion-toolbar.toolbar-container {
  // background: var(--ion-color-primary);
  // contain: none !important;
  // overflow: visible !important;
}

ion-toolbar > .toolbar-container {
  overflow: visible !important;
  contain: none !important;
}

.desktop-navbar {
  background-color: white !important;
}

.dropdown-toggle::after {
  display: inline-table !important;
}

.shine {
  background-color: #ddd;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 30%, #ddd 10%);
  background-size: 600px;
  animation: shine 2.1s infinite linear;
  background-position: 80% 0;
}

@keyframes shine {
  0% {
    background-position: 30% 0;
  }

  100% {
    background-position: -110% 0px;
  }
}

@keyframes shineLarge {
  0% {
    background-position: -32px;
  }

  100% {
    background-position: 208px;
  }
}

@keyframes load {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

// body {
//   background-color: var(--ion-color-primary);
// }

app-pay-in-store,
ion-menu {
  //margin-top: env(safe-area-inset-top);
  //margin-bottom: env(safe-area-inset-bottom);
}

.backgroundImage {
  transition: 0.5s;
  background-size: 200% !important;
}

.backgroundImageLoaded {
  background-size: cover !important;
}

.modalOffer .modal-wrapper {
  height: 80%;
  // top: 20%;
  position: absolute;
  display: block;
}

.infoPop {
  min-width: 250px;
  height: auto;
  padding: 12px !important;

  p {
    margin: 0;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  ion-button {
    color: white;
  }
}

.centerer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%);
  z-index: -1;
}

.powered-by {
  text-align: center;
  // position: absolute;
  font-size: 13px;
  color: var(--ion-color-dark);
  left: 0;
  right: 0;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px auto 5px auto;

  a {
    text-decoration: none;
    display: contents;
  }
}

.powered-by img {
  margin-top: 5px;
  height: 35px;
  vertical-align: middle;
}

.ios .nav-list {
  padding: 10px 0;
}

.desktop-nav-list {

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

}

.nav-list {
  ion-label {
    text-transform: uppercase !important;
    font-weight: 700;
  }
}

.nav-scroll {
  overflow-y: scroll;
}

.cart-count {
  left: calc(50% + 2px);
  background-color: var(--ion-color-tertiary);
  font-size: 12px;
  border-radius: 40%;
}

p:focus {
  outline: 0;
}

img:focus {
  outline: 0;
}

.modal-frame {
  .modal-wrapper {
    min-width: 991px;
    min-height: 550px;
  }
}

.cart-wrapper {
  border-left: 1px solid var(--de-border-color);
  border-right: 1px solid var(--de-border-color);
  text-align: center !important;
  height: 100%;
  //position: absolute;
  //top: 0;
  background-color: #FFFFFF;
}

.checkout-wrapper {
  border-bottom: 1px solid var(--de-border-color);
  padding: 25px 20px;
  position: relative;
  // width: 33%;
  z-index: 1;
  background-color: #FFFFFF;
}

.order {
  // text-transform: uppercase;
  text-align: left;
  font-size: 18px;

}

.subtotal {
  text-align: right;
  font-size: 18px;
}

.heading {
  text-align: center;
  border-bottom: 1px solid var(--de-border-color);
}

.heading-text {
  text-align: center;
  font-size: 30px !important;
  font-weight: bold;
  text-transform: uppercase;
}

.menu-column {
  height: 100%;
  overflow-y: scroll;
  padding: 0;
}

// DE PAGE LOADER

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #F7F7F7;
  width: 100px;
  padding: 10px;
  border-radius: 12px;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.circular {
  animation: rotate 1s linear infinite;
  height: 50px;
  transform-origin: center center;
  width: 50px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes color {

  100%,
  0% {
    stroke: var(--ion-color-dark);
  }

  40% {
    stroke: var(--ion-color-dark);
  }

  66% {
    stroke: var(--ion-color-dark);
  }

  80%,
  90% {
    stroke: var(--ion-color-dark);
  }
}

.showbox {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5%;
}

.pay-in-store {
  z-index: 15;

  ion-backdrop {
    opacity: 0 !important;
  }
}

.modal-dialog {
  max-width: 100vw;
  margin: 1rem auto !important;
}

.modal-dialog.no-inset-padding {
    .modal-content {
        padding: 0 !important;
    }
}

.modal-content {
  height: 100%;
  max-height: calc(100vh - 32px);
  overflow: hidden;
  overflow-y: auto;
  max-width: 750px;
  width: 100%;
  margin: 0 1rem;
  border-radius: var(--cms-card-radius);
  border: none;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.modal-dialog.modal-fullscreen {
  margin: 0 !important;
  background: var(--ion-color-light);

  .modal-content {
    max-width: 100%;
    max-height: 100%;
    margin: 0;

    .modal-body {
      padding: 0;
      max-width: 400px;
      background-color: transparent;
    }

  }
}

.modal-dialog.modal-fullscreen.p-0 {
  .modal-content {
    padding: 0 !important;
  }
}

.modal-dialog.notification {
  .modal-content {
    border-radius: 0.5rem;
  }
}

.condensed-modal {
  max-width: 500px;
}

.modal-fullscreen {

  .modal-dialog {
    height: 100%;
    margin: 0 !important;

    .modal-content {
      max-height: 100% !important;
      max-width: none !important;
      margin: 0 !important;

      app-modify-modal {

        .modify-modal {
          height: 100%;
          max-height: 100%;
        }

      }

    }

  }
}

.modal-dialog-centered {
  justify-content: center;
}

.pay-at-counter-modal, .credit-card-modal {
  .modal-dialog {
    .modal-content {
      overflow: visible !important;
    }
  }
}

.downloading-update-modal {
  .modal-dialog {
    .modal-content {
      padding: 0;
    }
  }
}

.de-border {
  border-top: 1px solid var(--de-border-color);
  border-bottom: 1px solid var(--de-border-color);
  border-right: 1px solid var(--de-border-color);
  border-left: 1px solid var(--de-border-color);

  &-top {
    @extend .de-border;
    border-bottom: unset;
    border-right: unset;
    border-left: unset;
  }

  &-bottom {
    @extend .de-border;
    border-top: unset;
    border-right: unset;
    border-left: unset;
  }

  &-right {
    @extend .de-border;
    border-top: unset;
    border-bottom: unset;
    border-left: unset;
  }

  &-left {
    @extend .de-border;
    border-top: unset;
    border-bottom: unset;
    border-right: unset;
  }

}

.de-border-i {
  border: 1px solid var(--de-border-color) !important;
}

:host zxing-scanner::ng-deep video {
  height: 100vh !important;
  object-fit: contain;
}

:host zxing-scanner::ng-deep {
  height: 100vh;
  border-style: solid;
  border-color: #5d78ff;
}

video {
  height: 100vh !important;
}

.de-light {
  background-color: white;
}

@media (max-width: 991px) {

  .modal {
    padding: none;
  }

  .modal-content {
    overflow-y: scroll;
    max-height: 100%;
    height: fit-content;
  }

  .tableside-modal-content {
    width: 100%;
    height: 100vh;
    place-items: center;
    overflow-y: auto;
  }

  .modal-dialog {
    padding: 0;
    width: 100vw;
    height: 100%;
    margin: 0 auto !important;
  }

  .card-info-modal {
    .modal-dialog {
      height: unset;
      padding: 0 16px;
    }
  }
}

//TABLESIDE MODALS

.exit-icon {
  width: 30px;
  height: 40px;
  margin-right: 20px;
  margin-top: 30px;
}

ion-content {
  --overflow: hidden !important;
  --keyboard-offset: 0 !important;
}

.order-submission-modal {
  .modal-dialog {
    height: unset;

    padding: 0;
    margin: auto;

    .modal-content {
      width: min(80vw, 400px);
    }
  }
}

// CUSTOM BUTTONS & CARDS & INPUTS FROM CMS


ion-card {
  border-radius: var(--cms-card-radius) !important;
}

.de-facebook-btn {
  --border-radius: var(--cms-btn-radius) !important;
  border-radius: var(--cms-btn-radius) !important;
}

.de-secondary-fill-btn {
  --border-radius: var(--cms-btn-radius);
  border-radius: var(--cms-btn-radius);
}

.de-secondary-btn {
  --border-radius: var(--cms-btn-radius) !important;
  border-radius: var(--cms-btn-radius) !important;
}

.de-tertiary-btn {
  --border-radius: var(--cms-btn-radius);
  border-radius: var(--cms-btn-radius);
}

.de-btn-radius {
  border-radius: var(--cms-btn-radius) !important;
  --border-radius: var(--cms-btn-radius) !important;
}

.de-btn-w-input {
  --border-radius: 0 var(--cms-input-radius) var(--cms-input-radius) 0 !important;
  border-radius: 0 var(--cms-input-radius) var(--cms-input-radius) 0 !important;
}

.de-input {
  --border-radius: var(--cms-input-radius) !important;
  border-radius: var(--cms-input-radius) !important;
}

.de-input-w-btn {
  --border-radius: var(--cms-input-radius) 0 0 var(--cms-input-radius) !important;
  border-radius: var(--cms-input-radius) 0 0 var(--cms-input-radius) !important;
}

.de-input-right {
  --border-radius: 0 var(--cms-input-radius) var(--cms-input-radius) 0 !important;
  border-radius: 0 var(--cms-input-radius) var(--cms-input-radius) 0 !important;
}

.de-input-left {
  --border-radius: var(--cms-input-radius) 0 0 var(--cms-input-radius) !important;
  border-radius: var(--cms-input-radius) 0 0 var(--cms-input-radius) !important;
}

.form-control.de-input-left {
  --border-radius: var(--cms-input-radius) 0 0 var(--cms-input-radius) !important;
  border-radius: var(--cms-input-radius) 0 0 var(--cms-input-radius) !important;
}

.form-control.de-input-right {
  --border-radius: 0 var(--cms-input-radius) var(--cms-input-radius) 0 !important;
  border-radius: 0 var(--cms-input-radius) var(--cms-input-radius) 0 !important;
}

.de-card-radius {
  border-radius: var(--cms-card-radius) !important;
  border: 1px solid var(--de-border-color);
  height: 100%;
  background-size: cover !important;
  background-position: center !important;
  position: relative;
}

.single-col-card {
  height: 250px;
}


//PROFILE PAGES

.side-nav {
  border-right: 1px solid var(--ion-color-dark);
  padding: 0;
  height: 100%;
  overflow-y: auto;
}

.card-scroll {
  padding: 0 0 400px 0;
  overflow-y: auto;
  height: 100%;
}

.profile-heading {
  background: var(--ion-color-dark);
  color: white;
  text-align: left;
  width: 100%;
  left: 0;
  right: 0;
  height: 62px;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 20px;
  padding: 16px 20px;

  .icon {
    color: white;
  }

  .header-font {
    vertical-align: text-bottom;
  }
}

.mobile-side-menu-footer {
  width: 100%;
  padding-bottom: 20px;
}

.side-menu-footer {
  margin: auto auto 10px;
}

.desktop-side-menu {
  overflow-y: auto;
  --overflow: auto !important;
  position: relative;
  height: 100vh;
}

.ham-icon {
  color: var(--ion-color-dark)
}

.mobile-nav-scroll {
  padding-top: env(safe-area-inset-top);
  overflow-y: auto;
  height: 100vh;
  --overflow: auto !important;
}

.bug-report-link {
  color: var(--ion-color-primary);
  text-decoration: underline;
  cursor: pointer;
  text-transform: capitalize !important;
}

img.ng-lazyloaded {
  animation: fadein .5s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.gm-style-iw {
  padding: 0 !important;
}

.gm-style .gm-style-iw-d {
  box-sizing: border-box;
  overflow: auto !important;
}

.button-native {
  border-radius: var(--cms-btn-radius) !important;
}

.ham-link {
  text-decoration: none;
  text-transform: capitalize !important;
}

.ngb-toasts {
  margin: 0.5rem 0 !important;
  right: unset !important;
  left: 50% !important;
  top: calc(64px + env(safe-area-inset-top, 0px)) !important;
  z-index: 120000 !important;
  transform: translate(-50%, 0);
  position: absolute;


  @media (min-width: 991px) {
    top: calc(80px + var(--toast-top-nav-padding) + env(safe-area-inset-top, 0px)) !important;
  }

  .toast {
    max-width: calc(100vw - 1rem);
    text-align: center;
  }
}

@media all and (max-width: 480px) {
  .sentry-error-embed {
    padding: 20px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.tableside-modal-icon {
  height: 100px;
  width: 100px;
}

// Use when fade page transition is on
@media (max-width: 991px) {
  .app-main > router-outlet ~ * {
    //position: absolute;
    width: 100%;
    //padding-top: calc(64px + env(safe-area-inset-top));
    //padding-top: calc((60px + env(safe-area-inset-top) + var(--announceheight)));
    height: 100%;
    //height: calc(100vh - (60px + env(safe-area-inset-top) + var(--announceheight)));
    overflow-y: auto;
    //padding-bottom: calc(78.1875px + env(safe-area-inset-bottom));
    display: block;

  }
}

@media (min-width: 992px) {
  .app-main > router-outlet ~ * {
    position: absolute;
    //padding-top: calc((120px + var(--announceheight)));
    height: 100%;
    //height: calc(100vh - (120px - var(--heightoffset)));
    overflow-y: auto;
    width: 100%;
  }
}

.no-nav-padding > router-outlet ~ * {
  padding-top: env(safe-area-inset-top);
  height: 100%;
}

.sticky-sidebar {
  position: sticky;
  top: 1.5rem;
  align-self: flex-start;
}

.profile-page {
  //overflow-y: auto;
  width: 100%;
  //overflow-x: hidden;
  padding-bottom: 96px;
  align-items: flex-start;
  min-height: 100vh;
  height: 100%;
}

.mobile-profile-page {
  //overflow-y: auto;
  width: 100%;
  //overflow-x: hidden;
  height: 100%;
  min-height: 100vh;
  padding-bottom: 96px;

  @media (max-width: 990px) {
    padding-bottom: 96px;
  }

  @media (min-width: 991px) {
    width: 100%;
  }

  @media (min-width: 1101px) {
    width: 100%;
  }
}

.fab-padding {
  padding-bottom: 92px;
}

.pac-container {
  // display google autocomplete over boostrap modal
  // bootstrap modals have a zindex of 1050
  z-index: 20005;
}

.modal {
  z-index: 20004;
}

.modal-backdrop {
  z-index: 20003 !important;
}

mwl-gauge {
  width: 150px;
  height: 150px;
  display: block;
  padding: 0;
}

mwl-gauge > .gauge > .dial {
  stroke: #eee;
  stroke-width: 3;
  fill: rgba(0, 0, 0, 0);
}

mwl-gauge > .gauge > .value {
  stroke: var(--ion-color-primary);
  stroke-width: 3 !important;
  fill: rgba(0, 0, 0, 0);
}

mwl-gauge > .gauge > .value-text {
  fill: var(--ion-color-dark);
  font-family: sans-serif;
  font-weight: bold;
  font-size: 0.75rem;
}

.header-underline {
  width: 90px;
  height: 1px;
  border: 1px solid var(--ion-color-primary);

  @media (min-width: 991px) {
    margin: 0 auto 16px auto;
  }
}

.show-modal {
  z-index: 20001
}

.img-border-inherit {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.box-shadow {
  box-shadow: 0 3px 6px #00000029;
}

.mobile-padding {
  padding: 0;

  @media (max-width: 991px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.hide-on-desktop {
  @media (min-width: 992px) {
    display: none !important;
  }
}

.hide-on-mobile {
  @media (max-width: 991px) {
    display: none !important;
  }
}

a.menu-link {
  color: unset;
}

a.menu-link:hover {
  color: unset;
}

.ham-container {
  display: grid;
  grid-column: 1;
  background-color: #2196F3;
  padding: 10px;
}

.ham-container {
  display: grid;
  grid-column: 1;
  background-color: #2196F3;
  padding: 10px;
}

.modal-exit-button::before {
  vertical-align: unset;
}

.modal-exit-button {
  font-size: 2rem;
  height: 31px;
  width: 31px;
  cursor: pointer;
  background-color: white;
  text-align: center;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.exit-d-absolute {
  position: absolute;
  top: 16px;
  right: 16px;
}

.modal-exit-button::before {
  vertical-align: unset;
}

//Kiosk modals

.full-page-modal {
  .modal-dialog {
    width: 100vw;
    height: 100vh;
    margin: 0 !important;
  }

  .modal-content {
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
  }
}

.kiosk-modal {
  @media (min-width: 991px) {
    //.modal-dialog {
    //  max-width: 500px;
    //}

    //.modal-content {
    //  max-width: 500px;
    //}
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .condensed-modal {
    max-width: 100%;
  }

  .modal-content {
    overflow-y: scroll;
    max-height: unset;
    height: 100%;
    margin: 0;
    border-radius: 0;
  }
  ion-modal {
    border-radius: 0 !important;
    --border-radius: 0 !important;
  }
}

h3.popover-header {
  margin: 0;
}

// Gap
.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 1rem;
}

.gap-4 {
  gap: 1.5rem;
}

.gap-5 {
  gap: 2rem;
}

.gap-6 {
  gap: 3rem;
}

// flex-grow
.grow-0 {
  flex-grow: 0;
}

.grow-1 {
  flex-grow: 1;
}

.grow-2 {
  flex-grow: 2;
}

.grow-3 {
  flex-grow: 3;
}

.grow-4 {
  flex-grow: 4;
}

.grow-5 {
  flex-grow: 5;
}

.grow-6 {
  flex-grow: 6;
}

// flex-shrink
.shrink-0 {
  flex-shrink: 0;
}

.shrink-1 {
  flex-shrink: 0;
}

// flex-basis
.basis-0 {
  flex-basis: 0;
}

.basis-100 {
  flex-basis: 100%;
}


// font-sizes
.font-size-h1 {
  font-size: 2rem !important;
}

.font-size-h2 {
  font-size: 1.75rem !important;
}

.font-size-subheading-1 {
  font-size: 1.5rem !important;
}

.font-size-subheading-2 {
  font-size: 1.25rem !important;
}

.font-size-body-large {
  font-size: 1.125rem !important;
}

.font-size-body-regular {
  font-size: 1rem !important;
}

.font-size-body-small {
  font-size: 0.875rem !important;
}

.font-size-small {
  font-size: 0.75rem !important;
}

.font-size-xsmall {
  font-size: 0.625rem !important;
}

// cursor
.pointer {
  cursor: pointer;
}

// radii
.cms-card-radius {
  border-radius: var(--cms-card-radius);

  &:focus {
    border-radius: var(--cms-card-radius);
  }
}

.cms-input-radius {
  border-radius: var(--cms-input-radius);
}

.cms-button-radius {
  border-radius: var(--cms-btn-radius);
}

.remove-top-radius {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.remove-bottom-radius {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

// horizontal row (wrap the hr tag that has this class on it with a div set with w-100 and mx-auto)
.de-hr-primary {
  width: 64px;
  border-width: 1px;
  margin: 0;
  border-top-color: var(--ion-color-primary);
}

.de-hr-secondary {
  width: 64px;
  border-width: 1px;
  margin: 0;
  border-top-color: var(--ion-color-secondary);
}

// transitions
.de-transition-default {
  transition: 0.3s;
}

// font colors
.color-primary {
  color: var(--ion-color-primary);
}

.color-secondary {
  color: var(--ion-color-secondary);
}

.color-dark {
  color: var(--ion-color-dark);
}

.color-light {
  color: var(--ion-color-light);
}

// background colors
.background-primary {
  background-color: var(--ion-color-primary);
}

.background-secondary {
  background-color: var(--ion-color-secondary);
}

.background-dark {
  background-color: var(--ion-color-dark);
}

.background-light {
  background-color: var(--ion-color-light);
}

.apple-button-contents {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--ion-color-primary);
  background-color: var(--ion-color-primary);
}

.custom-control.custom-radio .custom-control-label {
  cursor: pointer;
}

.custom-control.custom-checkbox .custom-control-label {
  min-height: fit-content;
  cursor: pointer;
}

.custom-control-input {
  width: 1.5rem;
  height: 1.5rem;
}

.custom-control-label {
  padding-left: 0.5rem;
  width: auto;
}

.custom-control-label::before {
  width: 1.5rem;
  height: 1.5rem;
  top: 0;
}

.custom-control-label::after {
  width: 1.5rem;
  height: 1.5rem;
  top: 0;
}

label.custom-control-label {
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.75;
}

.custom-control.custom-checkbox {
  min-height: fit-content !important;
}

.btn-primary {
  color: var(--ion-color-primary-contrast);
  background-color: var(--ion-color-primary);
  border-color: var(--ion-color-primary);

  &:active {
    background-color: var(--ion-color-primary-shade);
    border-color: var(--ion-color-primary-shade);
  }
}

//.dropdown-item:active {
//  color: var(--ion-color-primary-contrast);
//  text-decoration: none;
//  background-color: var(--ion-color-primary);
//
//  ion-icon,
//  .account-item,
//  i.bi {
//    color: var(--ion-color-primary-contrast) !important;
//  }
//}

.dummy-map {
  width: 110px;
  height: 110px;
  position: absolute;
  top: -100%;
  left: -100%;
}

.map-bg {
  background-color: var(--ion-color-light);
}

.list-bg {
  background-color: var(--ion-color-dark);
}

.map-toggle {
  animation-name: slideIn;
  animation-duration: 0.5s;
  animation-direction: alternate;
  animation-fill-mode: both;
  background-color: var(--ion-color-light);
  color: var(--ion-color-dark);
  transition: 0.3s;
}

google-map {
  div.gm-style-iw-chr {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;

    div.gm-style-iw-ch {
      padding-top: 0 !important;
    }
  }
}

@keyframes slideIn {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(100%);
  }
}

.underline-on-hover:hover {
  text-decoration: underline;
}

.list-toggle {
  animation-name: slideOut;
  animation-duration: 0.5s;
  animation-direction: alternate;
  animation-fill-mode: both;
  background-color: var(--ion-color-dark);
  color: var(--ion-color-light);
  transition: 0.3s;
}

@keyframes slideOut {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
}

.toggle-switch {
  border-radius: 100px !important;
  width: 62px;
  height: fit-content;
}

.toggle-circle {
  font-size: 0.875rem;
  padding: 0.375rem;
  border-radius: 100px;
}

// Bootstrap buttons

.btn-outline-secondary {
  color: var(--ion-color-secondary);
  border-color: var(--ion-color-secondary);
}

.btn-outline-secondary:active {
  --background: var(--ion-color-secondary) !important;
  background: var(--ion-color-secondary) !important;
  color: var(--ion-color-secondary-hover-txt) !important;
  border-color: var(--ion-color-secondary);
}

.btn-outline-secondary:hover {
  --background: var(--ion-color-secondary) !important;
  background: var(--ion-color-secondary) !important;
  color: var(--ion-color-secondary-hover-txt) !important;
  border-color: var(--ion-color-secondary);
}

.btn-outline-secondary:focus {
  background: var(--ion-color-secondary);
  color: var(--ion-color-secondary-txt);
  border-color: var(--ion-color-secondary);
}


.btn.btn-outline-primary {
  color: var(--ion-color-primary);
  border-color: var(--ion-color-primary);

  &:hover {
    color: var(--ion-color-primary-txt);
    background-color: var(--ion-color-primary);
    border-color: var(--ion-color-primary);
  }

  &:active {
    color: var(--ion-color-primary-txt);
    border-color: var(--ion-color-primary-shade);
    background-color: var(--ion-color-primary-shade);
  }
}

.btn-primary {
  color: var(--ion-color-primary-txt);
  background-color: var(--ion-color-primary);
  border-color: var(--ion-color-primary);
}

.btn-primary:active {
  --background: var(--ion-color-primary-hover) !important;
  background: var(--ion-color-primary-hover) !important;
  color: var(--ion-color-primary-hover-txt) !important;
  border-color: var(--ion-color-primary-hover);
}

.btn-primary:hover {
  --background: var(--ion-color-primary-hover) !important;
  background: var(--ion-color-primary-hover) !important;
  color: var(--ion-color-primary-hover-txt) !important;
  border-color: var(--ion-color-primary-hover);
}

.btn-primary:focus {
  background: var(--ion-color-primary);
  color: var(--ion-color-primary-txt);
  border-color: var(--ion-color-primary);
}

// Bootstrap buttons

.btn-outline-secondary {
  color: var(--ion-color-secondary);
  border-color: var(--ion-color-secondary);
}

.btn-outline-secondary:active {
  --background: var(--ion-color-secondary) !important;
  background: var(--ion-color-secondary) !important;
  color: var(--ion-color-secondary-hover-txt) !important;
  border-color: var(--ion-color-secondary);
}

.btn-outline-secondary:hover {
  --background: var(--ion-color-secondary) !important;
  background: var(--ion-color-secondary) !important;
  color: var(--ion-color-secondary-hover-txt) !important;
  border-color: var(--ion-color-secondary);
}

.btn-outline-secondary:focus {
  background: var(--ion-color-secondary);
  color: var(--ion-color-secondary-txt);
  border-color: var(--ion-color-secondary);
}


.btn-outline-primary {
  color: var(--ion-color-primary);
  border-color: var(--ion-color-primary);
}

.btn-outline-primary:hover {
  color: var(--ion-color-primary-txt);
  background-color: var(--ion-color-primary);
  border-color: var(--ion-color-primary);
}

.btn-primary {
  color: var(--ion-color-primary-txt);
  background-color: var(--ion-color-primary);
  border-color: var(--ion-color-primary);
}

.btn-primary:active {
  --background: var(--ion-color-primary-hover) !important;
  background: var(--ion-color-primary-hover) !important;
  color: var(--ion-color-primary-hover-txt) !important;
  border-color: var(--ion-color-primary-hover);
}

.btn-primary:hover {
  --background: var(--ion-color-primary-hover) !important;
  background: var(--ion-color-primary-hover) !important;
  color: var(--ion-color-primary-hover-txt) !important;
  border-color: var(--ion-color-primary-hover);
}

.btn-primary:focus {
  background: var(--ion-color-primary);
  color: var(--ion-color-primary-txt);
  border-color: var(--ion-color-primary);
}

.construction-container {
  height: 100vh;
  width: 100vw;
  display: flex;
}

.construction-logo {
  max-width: 40rem;
  margin: 0 auto;
}

app-modify-modal {
  height: 100%;
}

.form-control, .form-select {
  border: 1px solid var(--de-border-color);
  border-radius: var(--cms-input-radius) !important;

  &.de-input-w-btn {
    border-radius: var(--cms-input-radius) 0 0 var(--cms-input-radius) !important;
  }

}

.rounded-card-top {
  border-radius: var(--cms-card-radius) var(--cms-card-radius) 0 0 !important;
}

.rounded-card-bottom {
  border-radius: 0 0 var(--cms-card-radius) var(--cms-card-radius) !important;
}

.rounded-card-left {
  border-radius: var(--cms-card-radius) 0 0 var(--cms-card-radius) !important;
}

.rounded-card-right {
  border-radius: 0 var(--cms-card-radius) var(--cms-card-radius) 0 !important;
}

.rounded-btn-right {
  border-radius: 0 var(--cms-btn-radius) var(--cms-btn-radius) 0 !important;
}

.rounded-btn-top {
  border-radius: var(--cms-btn-radius) 0 !important;
}

.rounded-btn-bottom {
  border-radius: o var(--cms-btn-radius) !important;
}

.rounded-btn-left {
  border-radius: var(--cms-btn-radius) 0 0 var(--cms-btn-radius) !important;
}

.rounded-field-right {
  border-radius: 0 var(--cms-input-radius) var(--cms-input-radius) 0 !important;
}

.rounded-field-top {
  border-radius: var(--cms-input-radius) 0 !important;
}

.rounded-field-bottom {
  border-radius: o var(--cms-input-radius) !important;
}

.rounded-field-left {
  border-radius: var(--cms-input-radius) 0 0 var(--cms-input-radius) !important;
}

.de-card-body {
  background-color: var(--ion-background-color);

  &-bottom {
    @extend .de-card-body;
    border-bottom-left-radius: var(--cms-card-radius);
    border-bottom-right-radius: var(--cms-card-radius);
  }
}

ion-content.mobile-modal-content {
  --overflow: auto !important;
}

.border-primary {
  border: 1px solid var(--ion-color-primary) !important;
}

.mobile-modal-header {
  height: calc(64px + env(safe-area-inset-top));
  padding-top: env(safe-area-inset-top);
  width: 100%;
  display: grid;
  place-items: center start;
  grid-gap: 0.5em;
  border-bottom: 1px solid #DEDEDE;
  background-color: var(--white);
  position: sticky;
  top: 0;
  z-index: var(--zindex-sticky-nav);

  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  div.header-title {
    @extend .font-size-subheading-1;
    @extend .font-weight-bold !optional;
    width: 100%;
    padding-right: 1em;
  }

  &-back {
    @extend .mobile-modal-header;
    grid-template-columns: 64px 1fr;
    grid-gap: 0;

    button.back-button {
      height: 100%;
      width: 100%;
      display: grid;
      place-items: center;
      padding: 0;
    }

    div.header-title {
      width: 100%;
      padding-right: 1rem;
      font-size: 1.5rem;
      font-weight: bold;
    }

  }

  &-close {
    @extend .mobile-modal-header;
    grid-template-columns: 1fr 64px;

    button.back-button {
      height: 100%;
      width: 100%;
      display: grid;
      place-items: center;
      padding: 0;
    }

    div.header-title {
      width: 100%;
      padding-left: 1rem;
      font-size: 1.5rem;
      font-weight: bold;
    }
  }

}

.new-modal-content {
  .modal-wrapper {
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    overflow-y: auto;
    width: 750px;
    margin: 0 auto;
    max-width: none;
    border-radius: var(--cms-card-radius);
    border: none;
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    @media (min-width: 768px) {
      height: calc(100vh - 32px);
      max-height: calc(100vh - 32px);
    }
  }
}

.swiper-pagination-bullet-active {
  color: var(--ion-color-light);
  background-color: var(--ion-color-light);
}

.min-w-75 {
  min-width: 75%;
}

.min-w {

  &-0 {
    @extend .min-w;
    min-width: 0;
  }

  &-25 {
    @extend .min-w;
    min-width: 25%;
  }

  &-50 {
    @extend .min-w;
    min-width: 50%;
  }

  &-75 {
    @extend .min-w;
    min-width: 75%;
  }

  &-100 {
    @extend .min-w;
    min-width: 100%;
  }

}

.w-fit-content {
  width: fit-content !important;
}


.h-fit-content {
  height: fit-content;
}

.min-h-100vh {
  min-height: 100vh;
}

.cursor-pointer {
  cursor: pointer;
}

@media (min-width: 1440px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.bg-success {
  background-color: var(--ion-color-success) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle);
}

.de-bg-dark {
  background-color: var(--ion-color-dark)
}

.de-bg-medium {
  background-color: var(--ion-color-medium)
}

ngb-typeahead-window {
  overflow: auto;
  max-height: 20vh;
  z-index: 20005 !important;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.row > * {
  padding-right: initial;
  padding-left: initial;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: flex;
  min-height: calc(var(--font-size-base) * var(--line-height-base));
  //padding-left: calc(var(--custom-control-gutter) + var(--custom-control-indicator-size));
  print-color-adjust: exact; // Keep themed appearance for print
}

.badge.bg-light {
  color: var(--ion-color-dark);
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--ion-color-primary);
  background-color: red;
}

.input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.input-group > .input-group-text {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: var(--cms-input-radius) !important;
  border-bottom-right-radius: var(--cms-input-radius) !important;
  background-color: var(--ion-color-medium);
}

.input-group > .input-group-append {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: var(--cms-input-radius) !important;
  border-bottom-right-radius: var(--cms-input-radius) !important;
  background-color: var(--ion-color-medium);
}

.input-group > .input-group-append > .input-group-text {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: var(--cms-input-radius) !important;
  border-bottom-right-radius: var(--cms-input-radius) !important;
  background-color: var(--ion-color-medium);
}

.input-group > .input-group-append > button {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: var(--cms-input-radius) !important;
  border-bottom-right-radius: var(--cms-input-radius) !important;
  height: 100%;
}

.input-group > .input-group-prepend > button {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: var(--cms-input-radius) !important;
  border-bottom-left-radius: var(--cms-input-radius) !important;
  height: 100%
}

.input-group.start {
  .input-group-text {
    border-top-left-radius: var(--cms-input-radius) !important;
    border-bottom-left-radius: var(--cms-input-radius) !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .form-control {
    border-top-right-radius: var(--cms-input-radius) !important;
    border-bottom-right-radius: var(--cms-input-radius) !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    height: auto;
  }
}

.custom-checkbox > input, label {
  cursor: pointer;
  border-radius: var(--cms-btn-radius);
}

.custom-check-label {
  margin-bottom: 0;
}

.form-control, .form-select {
  height: calc(1.5em + 0.75rem + 2px);
}

ion-input.form-control {
  min-height: 38px !important;
  margin-top: 0px;
  margin-bottom: 0px;
  --border-width: 0px;
  border: 1px solid var(--de-border-color);
}

.form-group {
  margin-bottom: 1rem;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}


.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .container {
    max-width: 1500px;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .container {
    max-width: 1500px;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .container {
    max-width: 1500px;
  }
}

.form-switch {
  padding-left: 0;
}

.min-h-auto {
  min-height: auto;
}

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

textarea.form-control {
  min-height: 64px;
}

.form-row > .col, .form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.form-check {
  padding-left: 2em;

  &.form-switch {
    padding-left: 3em;
  }

  .form-check-label {
    font-size: .875rem;
    margin-bottom: 0;
    margin-top: 0.375em;
  }

  .form-check-input {
    margin-left: -2em;
    height: 24px;
    width: 24px;
    cursor: pointer;

    &:focus-visible {
      outline: -webkit-focus-ring-color auto 1px;
    }

    &.switch-input {
      width: 40px;
      margin-left: -3em;
    }
  }

  .form-check-input:checked {
    background-color: var(--ion-color-primary);
    border-color: var(--ion-color-primary);

    &:focus-visible {
      outline: -webkit-focus-ring-color auto 1px;
    }
  }

  .form-check-input:focus {
    border-color: var(--ion-color-primary);
    box-shadow: none;

    &:focus-visible {
      outline: -webkit-focus-ring-color auto 1px;
    }
  }
}

iframe[owner="archetype"][title="archetype"] {
  display: none !important;
}


#spreedly-number {
  iframe {
    display: block;
  }
}

#spreedly-cvv {
  iframe {
    display: block;
  }
}

.custom-field-group {
  gap: 0 0.5rem;
}

.custom-user-field {
  min-width: calc(50% - 0.25rem);
  flex-grow: 1;
  flex-basis: 0;
}

ion-modal.condensed {
  --height: auto;
  --width: auto;
}

.spinner {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  margin-bottom: max(env(safe-area-inset-bottom), 16px);
}

.spinner span {
  display: block;
  transform-origin: 32px 32px;
  animation: spin-opacity 1.2s linear infinite;
}

.spinner span:after {
  border-radius: 20%;
  background-color: #7f7f7f;
  content: " ";
  display: block;
  height: 14px;
  width: 5px;
  position: absolute;
  left: 30px;
  top: 5px;
}

.spinner span:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.spinner span:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.spinner span:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.spinner span:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.spinner span:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.spinner span:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.spinner span:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.spinner span:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.spinner span:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.spinner span:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.spinner span:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.spinner span:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes spin-opacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

ion-buttons {
  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }
}

ion-menu-button {
  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }
}

.kiosk {

  /* moved to global.scss*/
  /*:root {*/
  /*  --primary: var(--ion-color-primary)!important;*/
  /*  --primary-hover: var(--ion-color-primary) !important;*/
  /*  --primary-text: #ffffff !important;*/
  /*  --secondary: #6c757d !important;*/
  /*  --secondary-hover: #5a6268 !important;*/
  /*  --secondary-text: #ffffff !important;*/
  /*  --white: #ffffff !important;*/
  /*}*/

  :root {
    --kiosk-base-font-size: 16px;
  }

  @media (min-width: 576px) {
    :root {
      --kiosk-base-font-size: 16px;
    }
  }

  @media (min-width: 768px) {
    :root {
      --kiosk-base-font-size: 17px;
    }
  }

  @media (min-width: 992px) {
    :root {
      --kiosk-base-font-size: 18px;
    }
  }

  @media (min-width: 1200px) {
    :root {
      --kiosk-base-font-size: 19px;
    }
  }

  @media (min-width: 1400px) {
    :root {
      --kiosk-base-font-size: 20px;
    }
  }

  body {
    font-size: calc(16px + (18 - 16) * ((100vw - 767px) / (1080 - 767))) !important;
  }

  * {
    margin: 0;
    /*padding: 0;*/
    box-sizing: border-box;
    scroll-behavior: smooth;
  }

  .ki-buttons.outline-dark {
    display: flex;
    justify-content: center;
    padding: 1.5rem;
    background-color: white;
    border-color: var(--bs-dark);
    color: var(--bs-dark);
    font-weight: bold;
    font-size: 1.25rem;
    cursor: pointer;
  }

  .ki-buttons.dark {
    display: flex;
    justify-content: center;
    padding: 1.5rem;
    background-color: var(--bs-dark);
    border-color: var(--bs-dark);
    color: var(--white);
    font-weight: bold;
    font-size: 1.25rem;
    cursor: pointer;
  }

  .ingredient-card {
    //padding: 0.5rem 1rem;
    height: 5rem !important;

    &.checkbox {

      .ingredient-checkbox {
        height: 2rem !important;;
        width: 2rem !important;;
        min-width: 2rem !important;;
      }

      input {

        &:checked ~ .radio-dot {
          background-color: var(--ion-color-secondary);
          transition: all 0.15s ease-in-out;

          &:after {
            display: block;
            border: solid var(--ion-color-secondary-txt);
            border-width: 0.5rem;
            border-radius: 1000rem;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            width: 0.5rem !important;;
            height: 0.5rem !important;;
            position: absolute;
            top: calc(50% - 0.5rem) !important;;
            left: calc(50% - 0.5rem) !important;;
          }
        }
      }

      .checkbox-checkmark {
        height: 2rem !important;
        width: 2rem !important;;
        position: absolute;
        left: 1rem !important;;
        background-color: #ffffff;
        border: 1px solid var(--border-color);
        border-radius: 0.25rem;
      }

    }

    &.radio {

      .ingredient-radio {
        height: 2rem !important;;
        width: 2rem !important;;
        min-width: 2rem !important;;
      }

      .radio-dot {
        height: 2rem !important;;
        width: 2rem !important;;
        position: absolute;
        left: 1rem !important;;
        background-color: #ffffff;
        border: 1px solid var(--border-color);
        border-radius: 1000rem;
      }

    }

  }


  .product-name {
    font-size: 1.125rem;
    font-weight: bold;
    text-align: left;
  }

  .product-quantity {
    font-size: 1.125rem;
    text-align: left;
  }

  .product-description {
    font-size: 0.875rem;
    text-align: left;
  }

  .edit-remove {
    font-size: 1.125rem;
    text-align: left;
    font-weight: normal;
  }

  .mobile-product-description {
    font-weight: bold;
    font-size: 12px;
    font-style: italic;
    overflow-wrap: break-word;
    white-space: normal;
  }

  .crementers {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid var(--border-color);
    background-color: var(--white);
    width: fit-content !important;
    border-radius: 1000rem !important;
    height: 32px;
    overflow: hidden;
    box-shadow: transparent 0 0 0 1px inset, rgb(0 0 0 / 10%) 0 2px 8px;

    ion-icon {
      padding-top: 3px;
      padding-bottom: 2px;
    }
  }

  .qty_selector {
    transition: 0.1s;
    padding: 6px;
    background-color: var(--white);
    cursor: pointer;
  }

  .qty-one {
    background-color: #C8C7CC;
  }


  /* IGNORE */
  .de-border-primary {
    border: 1px solid var(--primary) !important;
  }

  .de-btn-primary {
    background-color: var(--primary);
    border-color: var(--primary);
    color: var(--primary-text);
  }

  .de-btn-primary:hover {
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
    color: var(--white);
  }

  .de-btn-primary:focus {
    background-color: var(--primary);
    border-color: var(--primary);
    color: var(--white);
    box-shadow: 0 0 0 0.2rem rgb(235 0 16 / 50%);
  }

  .de-btn-primary:not(:disabled):not(.disabled):active:focus {
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
    color: var(--white);
    box-shadow: 0 0 0 0.2rem rgb(235 0 16 / 50%);
  }

  .de-btn-outline-primary {
    border-color: var(--primary);
    color: var(--primary);
  }

  .de-btn-outline-primary:hover {
    border-color: var(--primary);
    background-color: var(--primary);
    color: var(--white);
  }

  .de-btn-outline-primary:focus {
    background-color: var(--primary);
    border-color: var(--primary);
    color: var(--white);
    box-shadow: 0 0 0 0.2rem rgb(235 0 16 / 50%);
  }

  .de-btn-outline-primary:not(:disabled):not(.disabled):active:focus {
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
    color: var(--white);
    box-shadow: 0 0 0 0.2rem rgb(235 0 16 / 50%);
  }

  .de-text-primary {
    color: var(--ion-color-primary);
  }

  a {
    color: var(--primary);
    text-decoration: none;
    background-color: transparent;
  }

  a:hover {
    color: var(--primary-hover);
  }

  /* borders */
  .ki-nav-vr {
    border-left: 1px solid;
    border-color: #dee2e6;
    height: 100%;
    margin: 0 0.5rem 0 0.5rem;
  }

  /* forms */

  .form-control-xl {
    height: calc(2em + 1rem + 2px);
    padding: .5rem 1rem;
    font-size: 2rem;
    line-height: 1.5;
    border-radius: .3rem;
  }

  /* colors */

  /* backgrounds */

  /* text */
  .text-limiter-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .text-limiter-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }


  .text-limiter-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .display-1 {
    font-size: 6rem;
  }

  .display-2 {
    font-size: 5rem;
  }

  .display-3 {
    font-size: 4.5rem;
  }

  .h1 {
    font-size: 3rem;
  }

  h1 {
    font-size: 3rem;
  }

  .h2 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  .h3 {
    font-size: 2rem;
  }

  h3 {
    font-size: 2rem;
  }

  .h4 {
    font-size: 1.75rem;
  }

  h4 {
    font-size: 1.75rem;
  }

  .h5 {
    font-size: 1.5rem !important;
  }

  h5 {
    font-size: 1.5rem !important;
  }

  .h6 {
    font-size: 1.25rem !important;
  }

  h6 {
    font-size: 1.25rem !important;
  }

  ::ng-deep .font-size-subheading-1 {
    font-size: 1.75rem !important;
  }

  ::ng-deep .font-size-body {
    font-size: 1.25rem !important;
  }

  ::ng-deep .font-size-body-small {
    font-size: 1.125rem !important;
  }

  ::ng-deep .font-size-small {
    font-size: 1rem !important;
  }

  ::ng-deep .option-group-name {
    font-size: 1.25rem !important;
  }

  ::ng-deep .option-group-description {
    font-size: 1rem !important;
  }

  ::ng-deep .option-name-text {
    font-size: 1rem !important;
    line-height: 1.625rem;
  }

  ::ng-deep .de-primary-btn {
    font-size: 1.125rem !important;
  }

  ::ng-deep .crementers {
    font-size: 1.125rem !important;
  }

  ::ng-deep .added-mod-text {
    font-size: 1.25rem !important;
  }

  ::ng-deep .mod-text {
    font-size: 1.125rem !important;
  }

  @media (min-width: 1081px) {

    .ki-buttons {
      display: flex;
      justify-content: center;
      padding: 1.5rem;
      background-color: var(--primary);
      border-color: var(--primary);
      color: var(--primary-text);
      font-weight: bold;
      font-size: 1.25rem !important;
      cursor: pointer;
      border-radius: var(--cms-btn-radius);
      height: auto;
    }

    .ki-buttons.outline-primary {
      display: flex;
      justify-content: center;
      padding: 1.5rem;
      background-color: white;
      border-color: var(--primary);
      color: var(--primary);
      font-weight: bold;
      font-size: 1.25rem;
      cursor: pointer;
    }

    .ki-buttons.secondary {
      display: flex;
      justify-content: center;
      padding: 1.5rem;
      background-color: var(--secondary);
      border-color: var(--secondary);
      color: var(--secondary-text);
      font-weight: bold;
      font-size: 1.25rem;
      cursor: pointer;
    }

    .ki-buttons.outline-secondary {
      display: flex;
      justify-content: center;
      padding: 1.5rem;
      background-color: transparent;
      border-color: var(--secondary);
      color: var(--secondary);
      font-weight: bold;
      font-size: 1.25rem;
      cursor: pointer;
    }

    .ki-buttons-small {
      display: flex;
      justify-content: center;
      padding: 0.5rem 1rem;
      background-color: var(--primary);
      border-color: var(--primary);
      color: var(--primary-text);
      font-weight: bold;
      font-size: 1.25rem;
      cursor: pointer;
    }

    .ki-buttons-small.outline-primary {
      display: flex;
      justify-content: center;
      padding: 0.5rem 1rem;
      background-color: transparent;
      border-color: var(--primary);
      color: var(--primary);
      font-weight: bold;
      font-size: 1.25rem;
      cursor: pointer;
    }

    .ki-buttons-small.secondary {
      display: flex;
      justify-content: center;
      padding: 0.5rem 1rem;
      background-color: var(--secondary);
      border-color: var(--secondary);
      color: var(--secondary-text);
      font-weight: bold;
      font-size: 1.25rem;
      cursor: pointer;
    }


    .ki-buttons-small.outline-secondary {
      display: flex;
      justify-content: center;
      padding: 0.5rem 1rem;
      background-color: transparent;
      border-color: var(--secondary);
      color: var(--secondary);
      font-weight: bold;
      font-size: 1.25rem;
      cursor: pointer;
    }
  }

  @media (max-width: 1080px) {

    //.display-1 {
    //  font-size: calc(80px + (96 - 80) * ((100vw - 767px) / (1080 - 767)));
    //}
    //
    //.display-2 {
    //  font-size: calc(72px + (80 - 72) * ((100vw - 767px) / (1080 - 767)));
    //}
    //
    //.display-3 {
    //  font-size: calc(64px + (72 - 64) * ((100vw - 767px) / (1080 - 767)));
    //}
    //
    //.h1 {
    //  font-size: calc(40px + (48 - 40) * ((100vw - 767px) / (1080 - 767)));
    //}
    //
    //h1 {
    //  font-size: calc(40px + (48 - 40) * ((100vw - 767px) / (1080 - 767)));
    //}
    //
    //.h2 {
    //  font-size: calc(32px + (40 - 32) * ((100vw - 767px) / (1080 - 767)));
    //}
    //
    //h2 {
    //  font-size: calc(32px + (40 - 32) * ((100vw - 767px) / (1080 - 767)));
    //}
    //
    //.h3 {
    //  font-size: calc(28px + (32 - 28) * ((100vw - 767px) / (1080 - 767)));
    //}
    //
    //h3 {
    //  font-size: calc(28px + (32 - 28) * ((100vw - 767px) / (1080 - 767)));
    //}
    //
    //.h4 {
    //  font-size: calc(24px + (28 - 24) * ((100vw - 767px) / (1080 - 767)));
    //}
    //
    //h4 {
    //  font-size: calc(24px + (28 - 24) * ((100vw - 767px) / (1080 - 767)));
    //}
    //
    //.h5 {
    //  font-size: calc(20px + (24 - 20) * ((100vw - 767px) / (1080 - 767)));
    //}
    //
    //h5 {
    //  font-size: calc(20px + (24 - 20) * ((100vw - 767px) / (1080 - 767)));
    //}
    //
    //.h6 {
    //  font-size: calc(16px + (20 - 16) * ((100vw - 767px) / (1080 - 767)));
    //}
    //
    //h6 {
    //  font-size: calc(16px + (20 - 16) * ((100vw - 767px) / (1080 - 767)));
    //}
    //
    //::ng-deep .font-size-subheading-1 {
    //  font-size: calc(24px + (28 - 24) * ((100vw - 767px) / (1080 - 767))) !important;
    //}
    //
    //::ng-deep .font-size-body {
    //  font-size: calc(16px + (20 - 16) * ((100vw - 767px) / (1080 - 767))) !important;
    //}
    //
    //::ng-deep .font-size-body-small {
    //  font-size: calc(16px + (18 - 16) * ((100vw - 767px) / (1080 - 767))) !important;
    //}
    //
    //::ng-deep .font-size-small {
    //  font-size: calc(14px + (16 - 14) * ((100vw - 767px) / (1080 - 767))) !important;
    //}
    //
    //::ng-deep .option-group-name {
    //  font-size: calc(16px + (20 - 16) * ((100vw - 767px) / (1080 - 767))) !important;
    //}
    //
    //::ng-deep .option-group-description {
    //  font-size: calc(16px + (18 - 16) * ((100vw - 767px) / (1080 - 767))) !important;
    //}
    //
    //::ng-deep .option-name-text {
    //  font-size: calc(16px + (20 - 16) * ((100vw - 767px) / (1080 - 767))) !important;
    //  line-height: calc(22px + (20 - 16) * ((100vw - 767px) / (1080 - 767)));
    //}
    //
    //::ng-deep .de-primary-btn {
    //  font-size: calc(16px + (18 - 16) * ((100vw - 767px) / (1080 - 767))) !important;
    //}
    //
    //::ng-deep .crementers {
    //  font-size: calc(16px + (18 - 16) * ((100vw - 767px) / (1080 - 767))) !important;
    //}
    //
    //::ng-deep .added-mod-text {
    //  font-size: calc(16px + (20 - 16) * ((100vw - 767px) / (1080 - 767))) !important;
    //}
    //
    //::ng-deep .mod-text {
    //  font-size: calc(16px + (18 - 16) * ((100vw - 767px) / (1080 - 767))) !important;
    //}

    .ki-buttons {
      display: flex;
      justify-content: center;
      padding: 1.5rem;
      background-color: var(--primary);
      border-color: var(--primary);
      color: var(--primary-text);
      font-weight: bold;
      font-size: 1.25rem;
      cursor: pointer;
      border-radius: var(--cms-btn-radius);
      height: auto;
    }

    .ki-buttons.outline-primary {
      display: flex;
      justify-content: center;
      padding: 1.5rem;
      background-color: white;
      border-color: var(--primary);
      color: var(--primary);
      font-weight: bold;
      font-size: 1.25rem;
      cursor: pointer;
    }

    .ki-buttons.secondary {
      display: flex;
      justify-content: center;
      padding: 1.5rem;
      background-color: var(--secondary);
      border-color: var(--secondary);
      color: var(--secondary-text);
      font-weight: bold;
      font-size: 1.25rem;
      cursor: pointer;
    }

    .ki-buttons.outline-secondary {
      display: flex;
      justify-content: center;
      padding: 1.5rem;
      background-color: transparent;
      border-color: var(--secondary);
      color: var(--secondary);
      font-weight: bold;
      font-size: 1.25rem;
      cursor: pointer;
    }

    .ki-buttons-small {
      display: flex;
      justify-content: center;
      padding: 0.5rem 1rem;
      background-color: var(--primary);
      border-color: var(--primary);
      color: var(--primary-text);
      font-weight: bold;
      font-size: 1.25rem;
      cursor: pointer;
    }

    .ki-buttons-small.outline-primary {
      display: flex;
      justify-content: center;
      padding: 0.5rem 1rem;
      background-color: transparent;
      border-color: var(--primary);
      color: var(--primary);
      font-weight: bold;
      font-size: 1.25rem;
      cursor: pointer;
    }

    .ki-buttons-small.secondary {
      display: flex;
      justify-content: center;
      padding: 0.5rem 1rem;
      background-color: var(--secondary);
      border-color: var(--secondary);
      color: var(--secondary-text);
      font-weight: bold;
      font-size: 1.25rem;
      cursor: pointer;
    }


    .ki-buttons-small.outline-secondary {
      display: flex;
      justify-content: center;
      padding: 0.5rem 1rem;
      background-color: transparent;
      border-color: var(--secondary);
      color: var(--secondary);
      font-weight: bold;
      font-size: 1.25rem;
      cursor: pointer;
    }
  }

  @media (min-width: 992px) {
    ::ng-deep .col-lg-3 {
      flex: 0 0 33%;
      max-width: 33%;
    }

    /*::ng-deep .option-group-items {*/
    /*  justify-content: space-between;*/
    /*}*/
  }


  .fs-small {
    font-size: 0.875rem;
  }

  /* flex */
  .flex-1 {
    flex: 1 0 auto;
  }

  .flex-flow-col-nowrap {
    flex-flow: column nowrap;
    place-content: center flex-start;
    align-items: stretch;
  }

  .fg-0 {
    flex-grow: 0;
    flex-basis: auto;
  }

  .fg-1 {
    flex-grow: 1;
    flex-basis: auto;
  }

  .fg-2 {
    flex-grow: 2;
    flex-basis: auto;
  }

  .fg-3 {
    flex-grow: 3;
    flex-basis: auto;
  }

  .fg-4 {
    flex-grow: 4;
    flex-basis: auto;
  }

  .fb-0 {
    flex-basis: 0 !important;
  }

  .fs-1 {
    flex-shrink: 1;
  }

  .gap-1 {
    gap: 0.5rem;
  }

  .gap-2 {
    gap: 1rem;
  }

  .gap-3 {
    gap: 1.5rem;
  }

  .gap-4 {
    gap: 2rem;
  }

  .gap-5 {
    gap: 2.5rem;
  }

  .gap-6 {
    gap: 3rem;
  }

  .flex-gap {
    gap: 1rem;
  }

  .flex-gap-product {
    gap: 0.5rem;
  }

  /* grids */
  .d-grid-4 {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }

  .d-grid-3 {
    display: grid;
    grid-template-columns: 1fr !important;
    gap: 0.5rem;
  }

  /* z-index */
  .zi-nav {
    z-index: 2000;
  }

  .zi-modal {
    z-index: 2001;
  }

  .zi-cs {
    z-index: 1500;
  }

  .zi-main {
    z-index: 1000;
  }

  /* kiosk home footer */

  .ki-home-content {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .ki-home-footer {
    height: 30vh;
    background-color: var(--dark);
  }

  .scrollTop {
    position: absolute;
    bottom: 1.5rem;
    right: 1.5rem;
    padding: 1rem;
    z-index: 2;
    background: var(--primary);
    color: var(--primary-text);
    border-radius: 0.25rem;
    cursor: pointer;
    transition: all 0.5s ease 0s;
  }

  .scrollTop:hover {
    background-color: var(--primary-hover);
    color: var(--primary-text);
  }

  .modal-footer {
    gap: 1rem;
  }

  .ki-buttons:hover {
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
    color: var(--primary-text);

  }

  .ki-buttons:active {
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
    color: var(--primary-text);
  }

  /*.ki-buttons:focus {*/
  /*  background-color: var(--primary-hover);*/
  /*  border-color: var(--primary-hover);*/
  /*  box-shadow: 0 0 0 0.2rem rgb(235 0 16 / 50%);*/
  /*  color: var(--primary-text);*/
  /*}*/

  .ki-buttons.outline-primary:not(:disabled):not(.disabled):active:focus {
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
    box-shadow: 0 0 0 0.2rem rgb(235 0 16 / 50%);
    color: var(--primary-text);
  }


  .ki-buttons.outline-primary:hover {
    background-color: var(--primary);
    border-color: var(--primary);
    color: var(--primary-text);
  }

  .ki-buttons.outline-primary:active {
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
    color: var(--primary-text);
  }

  .ki-buttons.outline-primary:focus {
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
    box-shadow: 0 0 0 0.2rem rgb(235 0 16 / 50%);
    color: var(--primary-text);
  }

  .ki-buttons.outline-primary:not(:disabled):not(.disabled):active:focus {
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
    box-shadow: 0 0 0 0.2rem rgb(235 0 16 / 50%);
    color: var(--primary-text);
  }

  .ki-buttons.secondary:hover {
    background-color: var(--secondary-hover);
    border-color: var(--secondary-hover);
    color: var(--secondary-text);

  }

  .ki-buttons.secondary:active {
    background-color: var(--secondary-hover);
    border-color: var(--secondary-hover);
    color: var(--secondary-text);
  }

  .ki-buttons.secondary:focus {
    background-color: var(--secondary-hover);
    border-color: var(--secondary-hover);
    box-shadow: 0 0 0 0.2rem rgb(235 0 16 / 50%);
    color: var(--secondary-text);
  }

  .ki-buttons.outline-secondary:not(:disabled):not(.disabled):active:focus {
    background-color: var(--secondary-hover);
    border-color: var(--secondary-hover);
    box-shadow: 0 0 0 0.2rem rgb(235 0 16 / 50%);
    color: var(--secondary-text);
  }

  .ki-buttons.outline-secondary:hover {
    background-color: var(--secondary);
    border-color: var(--secondary);
    color: var(--secondary-text);
  }

  .ki-buttons.outline-secondary:active {
    background-color: var(--secondary-hover);
    border-color: var(--secondary-hover);
    color: var(--secondary-text);
  }

  .ki-buttons.outline-secondary:focus {
    background-color: var(--secondary-hover);
    border-color: var(--secondary-hover);
    box-shadow: 0 0 0 0.2rem rgb(235 0 16 / 50%);
    color: var(--secondary-text);
  }

  .ki-buttons.outline-secondary:not(:disabled):not(.disabled):active:focus {
    background-color: var(--secondary-hover);
    border-color: var(--secondary-hover);
    box-shadow: 0 0 0 0.2rem rgb(235 0 16 / 50%);
    color: var(--secondary-text);
  }

  .ki-buttons-small:hover {
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
    color: var(--primary-text);

  }

  .ki-buttons-small:active {
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
    color: var(--primary-text);
  }

  .ki-buttons-small:focus {
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
    box-shadow: 0 0 0 0.2rem rgb(235 0 16 / 50%);
    color: var(--primary-text);
  }

  .ki-buttons-small.outline-primary:not(:disabled):not(.disabled):active:focus {
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
    box-shadow: 0 0 0 0.2rem rgb(235 0 16 / 50%);
    color: var(--primary-text);
  }

  .ki-buttons-small.outline-primary:hover {
    background-color: var(--primary);
    border-color: var(--primary);
    color: var(--primary-text);
  }

  .ki-buttons-small.outline-primary:active {
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
    color: var(--primary-text);
  }

  .ki-buttons-small.outline-primary:focus {
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
    box-shadow: 0 0 0 0.2rem rgb(235 0 16 / 50%);
    color: var(--primary-text);
  }

  .ki-buttons-small.outline-primary:not(:disabled):not(.disabled):active:focus {
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
    box-shadow: 0 0 0 0.2rem rgb(235 0 16 / 50%);
    color: var(--primary-text);
  }

  .ki-buttons-small.secondary:hover {
    background-color: var(--secondary-hover);
    border-color: var(--secondary-hover);
    color: var(--secondary-text);

  }

  .ki-buttons-small.secondary:active {
    background-color: var(--secondary-hover);
    border-color: var(--secondary-hover);
    color: var(--secondary-text);
  }

  .ki-buttons-small.secondary:focus {
    background-color: var(--secondary-hover);
    border-color: var(--secondary-hover);
    box-shadow: 0 0 0 0.2rem rgb(235 0 16 / 50%);
    color: var(--secondary-text);
  }

  .ki-buttons-small.secondary:not(:disabled):not(.disabled):active:focus {
    background-color: var(--secondary-hover);
    border-color: var(--secondary-hover);
    box-shadow: 0 0 0 0.2rem rgb(235 0 16 / 50%);
    color: var(--secondary-text);
  }

  .ki-buttons-small.outline-secondary:hover {
    background-color: var(--secondary);
    border-color: var(--secondary);
    color: var(--secondary-text);
  }

  .ki-buttons-small.outline-secondary:active {
    background-color: var(--secondary-hover);
    border-color: var(--secondary-hover);
    color: var(--secondary-text);
  }

  .ki-buttons-small.outline-secondary:focus {
    background-color: var(--secondary-hover);
    border-color: var(--secondary-hover);
    box-shadow: 0 0 0 0.2rem rgb(235 0 16 / 50%);
    color: var(--secondary-text);
  }

  .ki-buttons-small.outline-secondary:not(:disabled):not(.disabled):active:focus {
    background-color: var(--secondary-hover);
    border-color: var(--secondary-hover);
    box-shadow: 0 0 0 0.2rem rgb(235 0 16 / 50%);
    color: var(--secondary-text);
  }

  .ki-crementer-input {
    width: 42px;
  }


  /* product card */
  .product-card-grid {
    display: grid;
    grid-template-columns: 1fr 3fr;
  }

  .product-card-info {
    position: absolute;
    top: 0;
    right: 0;
    height: 40px;
    width: 100%;
    display: none !important;
    justify-content: flex-end;
    align-items: center;
  }

  .product-card-img {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
    object-fit: cover;
    border-top-left-radius: var(--cms-card-radius);
    border-top-right-radius: var(--cms-card-radius);
  }

  .product-card-img.mobile {
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    object-fit: cover;
  }

  .product-card-bg {
    background-color: #ffffff;
    color: var(--dark) !important;
    height: 40px;
    width: fit-content;
    gap: 0.25rem;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: var(--cms-card-radius);
    border-top-right-radius: var(--cms-card-radius);
    border-bottom: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
  }

  .product-card-bg:focus {
    background-color: #f8f9fa;
  }

  /* kiosk categories scrollbar */

  .ki-cs-position {
    position: sticky;
    top: 94px;
  }

  .ki-cs-scrollbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    min-height: 3rem;
    overflow-x: auto;
  }

  .ki-cs-category {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 0.5rem 0.25rem 0.5rem;
    padding-bottom: 0.25rem;
    border-bottom: 2px solid transparent;
  }

  .ki-cs-category:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 0.5rem 0.25rem 0;
    border-bottom: 2px solid transparent;
  }

  .ki-cs-category:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 0 0.25rem 0.5rem;
    padding-right: 1rem;
    border-bottom: 2px solid transparent;
  }

  .ki-cs-category:active {
    color: var(--primary);
    border-bottom: 2px solid var(--primary);
  }

  /* kiosk nav */
  .ki-nav-btn {
    height: 3rem;
    min-width: 60px;
  }

  .ki-nav-logo {
    height: 3rem;
  }

  .ki-nav {
    position: sticky;
    top: 0;
  }

  /* kiosk home */

  .ki-home-top {
    flex: 1 1 0;
    display: flex;
  }

  .ki-home-bottom {
    height: 30vh;
    max-height: 300px;
  }

  .ki-home-logo {
    max-width: 300px;
  }

  .ki-home-buttons {
    min-width: 144px;

    max-width: 365px;
  }

  .ki-home-featureimg {
    object-fit: cover;
  }

  /* cart */
  .sticky-top-cart {
    position: sticky;
    top: 94px;
  }

  .sticky-bottom-cart {
    position: sticky;
    bottom: 0;
  }

  .cart-contents-mb {
    margin-bottom: 1.5rem !important;
    border-bottom: 1px solid #dee2e6 !important;
  }

  .cart-contents-mb:last-child {
    margin-bottom: 0 !important;
    border-bottom: transparent !important;
  }

  .cart-height {
    height: calc(100vh - 110px);
  }

  .viewcart-height {
    max-height: calc(100vh - 187px);
  }

  .cart-scroll {
    overflow-y: auto;
    height: 100%;
  }

  .cart-hidden {
    overflow-y: hidden;
  }

  .cart-images {
    height: 5rem;
    width: 5rem;
    object-fit: cover;
  }

  /* misc */

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  .rounded-left {
    border-radius: 0.25rem 0 0 0.25rem !important;
  }

  .rounded-right {
    border-radius: 0 0.25rem 0.25rem 0 !important;
  }

  .rounded {
    border-radius: var(--cms-card-radius) !important;
  }

  .h-screen {
    min-height: 100vh;
  }

  .h-screen.home {
    height: 100vh;
  }

  .height-fit-content {
    height: fit-content;
  }

  .width-fit-content {
    width: fit-content;
  }

  .pointer {
    cursor: pointer;
  }

  .incrementer-text-size {
    height: 24px;
    width: 24px;
  }

  .step-circle {
    max-height: 24px;
    width: 24px;
    aspect-ratio: 1;
  }

  .close {
    background-color: transparent;
    color: var(--ion-text-color);

    svg {
      color: var(--ion-text-color);
    }
  }

  .collapsing.width {
    -webkit-transition-duration: 0s;
    transition-duration: 0s;
    -webkit-transition-property: width, visibility;
    transition-property: width, visibility;
    width: 0;
    height: auto;
  }

  .popover-header {
    margin-top: 0 !important;
  }

  .crementers {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid var(--border-color);
    background-color: var(--white);
    width: fit-content !important;
    border-radius: 1000rem !important;
    height: 59px !important;
    overflow: hidden;
    box-shadow: transparent 0 0 0 1px inset, rgb(0 0 0 / 10%) 0 2px 8px;

    ion-icon {
      padding-top: 3px;
      padding-bottom: 2px;
    }

    .qty_selector {
      transition: 0.1s;
      padding: 6px;
      background-color: var(--white);
      width: 57px;
      cursor: pointer;
      justify-content: center;
      font-size: 1.75rem;
    }

    .qty-one {
      background-color: #C8C7CC;
    }

    .number {
      width: 57px;
      font-size: 20px;
    }
  }

  .option-pill-image {
    height: 2rem;
    width: 2rem;
  }

  .option-pill-icon {
    height: 2rem !important;
    width: 2rem !important;
  }

  .option-card-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr !important;
    grid-template-rows: auto;
    gap: 0.75rem;
  }

  .order-method-logo-image {
    max-height: var(--order-method-logo-height);
    max-width: var(--order-method-logo-width);
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  .z-7 {
    z-index: 7;
  }

  .form-floating > .form-control, .form-floating > .form-control-plaintext, .form-floating > .form-select {
    height: 5rem;
    min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
    line-height: 1.25;
  }

  .form-floating input {
    max-height: 5rem;
    font-size: 1.5rem;
  }

  .form-floating > label {
    position: absolute;
    top: 0.6875rem;
    left: 0;
    z-index: 2;
    height: 100%;
    padding: 1rem 0.75rem;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: var(--bs-border-width) solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  }

  .form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label {
    color: rgba(var(--bs-body-color-rgb), 0.65);
    transform: scale(0.85) translateY(-0.875rem) translateX(0.15rem);
  }






  /* media queries */

  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
    .d-grid-4 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }

    .w-sm-100 {
      width: 100% !important;
    }
  }

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    .d-grid-4 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 1rem;
    }

    .d-grid-3 {
      display: grid;
      grid-template-columns: 1fr 1fr !important;
      gap: 1rem;
    }

    .w-md-100 {
      width: 100% !important;
    }

    .option-card-container {
      grid-template-columns: 1fr 1fr !important;
    }

  }

  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    .ki-nav-btn {
      height: 3rem;
    }

    .ki-nav-vr {
      border-left: 1px solid;
      border-color: #dee2e6;
      height: 100%;
      margin: 0 1rem 0 1rem;
    }

    .d-grid-4 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 1rem;
    }

    .d-grid-3 {
      display: grid;
      grid-template-columns: 1fr 1fr !important;
      gap: 1rem;
    }

    .option-card-container {
      grid-template-columns: 1fr 1fr 1fr !important;
    }
  }

  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {

    .d-grid-4 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 1rem;
    }

    .d-grid-3 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr !important;
      gap: 1rem;
    }

  }

  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1440px) {

    .d-grid-4 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      gap: 1rem;
    }

    .d-grid-3 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr !important;
      gap: 1rem;
    }

    .option-card-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr !important;
      grid-template-rows: auto;
      gap: 0.75rem;
    }

  }

}

.de-text-primary {
  color: var(--ion-color-primary);
}

.text-underline {
  text-decoration: underline dotted;
  text-decoration-thickness: 2px;
  text-underline-offset: 3px;
}

.external-link {
  text-decoration-color: var(--ion-color-dark);
}
